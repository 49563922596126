// import React, { useEffect } from 'react';

// import { yupResolver } from '@hookform/resolvers/yup';
// import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
// import Card from '@mui/material/Card';
// import Grid from '@mui/material/Grid';
// import Stack from '@mui/material/Stack';
// import Typography from '@mui/material/Typography';
// import { useForm } from 'react-hook-form';
// import { useNavigate } from 'react-router-dom';
// import * as yup from 'yup';

// import HookTextField from '../../components/HookTextfield';
// import Loader from '../../components/Loader';
// import LoadingButton from '../../components/LoadingButton';
// import {
//   useGetSettingsQuery,
//   useUpdateSettingsMutation,
// } from '../../store/apis/setting';
// import { notify } from '../../utils/commonFunctions';
// import textConst from '../../utils/textConst';
// import validationSchemas from '../../utils/validationSchemas';

// const validationSchema = yup.object({
//   assessmentPassPercentage: validationSchemas.assessmentPassPercentage,
// });

// const Settings = () => {
//   const navigate = useNavigate();
//   const { handleSubmit, control, setValue } = useForm({
//     defaultValues: {
//       assessmentPassPercentage: '',
//     },
//     resolver: yupResolver(validationSchema),
//     mode: 'onTouched',
//     reValidateMode: 'onChange',
//   });
//   const { data, isLoading, isError, error } = useGetSettingsQuery();

//   const [
//     updateSettings,
//     {
//       isSuccess: isUpdateSuccess,
//       isError: isUpdateError,
//       error: updateError,
//       isLoading: updateIsLoading,
//     },
//   ] = useUpdateSettingsMutation();

//   useEffect(() => {
//     if (isUpdateSuccess) {
//       notify('Settings updated successfully', 'success', 1200);
//       navigate(-1);
//     }
//   }, [isUpdateSuccess, navigate]);

//   const onSubmit = (formData: any) => {
//     const requestData = JSON.parse(JSON.stringify(data));
//     requestData.assessmentPassingPer.value = formData.assessmentPassPercentage;
//     updateSettings(requestData);
//   };

//   useEffect(() => {
//     if (isError) {
//       notify(error?.data?.message, 'error', 1200);
//     }
//   }, [error?.data?.message, isError]);

//   useEffect(() => {
//     if (isUpdateError) {
//       notify(updateError?.data?.message, 'error');
//     }
//   }, [isUpdateError, updateError]);

//   useEffect(() => {
//     if (data && !isLoading) {
//       setValue('assessmentPassPercentage', data?.assessmentPassingPer?.value);
//     }
//   }, [data, isLoading, setValue]);

//   return isLoading ? (
//     <Loader />
//   ) : (
//     <form onSubmit={handleSubmit(onSubmit)}>
//       <Typography variant="h4" mb={3}>
//         {textConst.settings}
//       </Typography>
//       <Card sx={{ p: 3, minHeight: '5%' }}>
//         <Grid container spacing={3}>
//           <Grid item xs={12}>
//             <HookTextField
//               control={control}
//               type="text"
//               name="assessmentPassPercentage"
//               label="Assessment Pass Percentage"
//               inputProps={{ maxLength: 3 }}
//             />
//           </Grid>
//         </Grid>
//       </Card>
//       <Stack direction={'row'} gap={2} justifyContent={'end'} mt={3}>
//         <Button
//           sx={{ width: { xs: '100%', sm: 'inherit' } }}
//           variant="outlined"
//           color="secondary"
//           onClick={() => {
//             navigate(-1);
//           }}
//         >
//           {textConst.cancel}
//         </Button>
//         <Box
//           sx={{ cursor: 'not-allowed', width: { xs: '100%', sm: 'inherit' } }}
//         >
//           <LoadingButton
//             variant="contained"
//             color="secondary"
//             type="submit"
//             isLoading={updateIsLoading}
//             disabled={updateIsLoading}
//             sx={{ minWidth: '150px', width: { xs: '100%', sm: 'inherit' } }}
//           >
//             {textConst.save}
//           </LoadingButton>
//         </Box>
//       </Stack>
//     </form>
//   );
// };

// export default Settings;

import React, { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import AlertSettingDialog from '../../../components/AlertSettingDialog';
import CommonTableCourseSetting from '../../../components/CommonTableCourseSetting';
import CustomTabs from '../../../components/CustomTabs';
import HookAutoComplete from '../../../components/HookAutoComplete';
import HookTextField from '../../../components/HookTextfield';
import Loader from '../../../components/Loader';
import LoadingButton from '../../../components/LoadingButton';
import SearchBarReport from '../../../components/SearchBarReport';
import {
  useGetAllCoursesQuery,
  useGetAllSettingCoursesQuery,
} from '../../../store/apis/courses';
import { useCreateCourseSettingsMutation } from '../../../store/apis/setting';
import { userSelector } from '../../../store/user';
import { notify } from '../../../utils/commonFunctions';
import textConst from '../../../utils/textConst';
import validationSchemas from '../../../utils/validationSchemas';

const validationSchema = yup.object({
  assessmentPassPercentage: validationSchemas.assessmentPassPercentage,
});

const Settings = () => {
  const navigate = useNavigate();

  const users = useSelector(userSelector);

  const [searchUserVal, setSearchUserVal] = useState<any>(null);
  const [searchVal, setSearchVal] = useState<any>(null);
  const [selectedCourseName, setSelectedCourseName] = useState<string | null>(
    null
  );

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [sortField, setSortField] = useState<any>(null);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [openDialog, setOpenDialog] = useState(false);

  const [selectedCourseNameEdit, setSelectedCourseNameEdit] =
    useState<any>(null);
  const [courseEditData, setCourseEditData] = useState<any>(null);
  const [courseEditId, setCourseEditId] = useState<any>(null);

  const handleOpen = (row: any) => {
    setOpenDialog(true);
    setCourseEditId(row?.courseId?._id);
    setSelectedCourseNameEdit(row?.courseId?.name);
    setCourseEditData(row?.assessmentPassingPer?.value);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handlePageChange = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const { handleSubmit, control, setValue, reset } = useForm({
    defaultValues: {
      assessmentPassPercentage: '',
    },
    resolver: yupResolver(validationSchema),
    mode: 'onTouched',
    reValidateMode: 'onChange',
  });

  const [
    createCourseSettings,
    {
      isSuccess: isUpdateSuccess,
      isError: isUpdateError,
      error: updateError,
      isLoading: updateIsLoading,
    },
  ] = useCreateCourseSettingsMutation();

  const {
    data: courseData,
    isLoading: isCourseLoading,
    isError: isCourseError,
    error: courseError,
    isFetching: isCourseFetching,
    refetch: refetchCourseSettings,
  } = useGetAllCoursesQuery();

  const {
    data: courseSettingData,
    refetch,
    isLoading: isCourseSettingLoading,
    isError: isCourseSettingError,
    error: courseSettingError,
    isFetching: isCourseSettingFetching,
  } = useGetAllSettingCoursesQuery();

  const filteredCourseData = courseData?.courses.filter((course: any) => {
    return !courseSettingData?.some(
      (settingCourse: any) => settingCourse?.courseId?.name === course?.name
    );
  });

  const courseList = [
    ...(filteredCourseData?.map((course: any) => ({
      _id: course?._id,
      name: course?.name,
    })) || []),
  ];

  useEffect(() => {
    if (isUpdateSuccess) {
      notify('Course Setting added successfully', 'success', 1200);
      setSelectedCourseName(null);
      setSearchUserVal(null);
      reset();
      setValue('assessmentPassPercentage', '');
      refetch();
      refetchCourseSettings();
    }
  }, [
    isUpdateSuccess,
    navigate,
    refetch,
    refetchCourseSettings,
    reset,
    setValue,
  ]);

  useEffect(() => {
    refetch();
    refetchCourseSettings();
  }, [refetch, refetchCourseSettings]);

  useEffect(() => {
    if (isCourseSettingError) {
      notify(updateError?.data?.message, 'error');
    }
  }, [isCourseSettingError, courseSettingError]);

  useEffect(() => {
    if (isUpdateError) {
      notify(updateError?.data?.message, 'error');
    }
  }, [isUpdateError, updateError]);

  const onSubmit = (formData: any) => {
    const assessmentPercentageValue = formData.assessmentPassPercentage;

    const bodyData = {
      courseId: searchUserVal,
      assessmentPassingPer: {
        value: assessmentPercentageValue,
      },
    };
    createCourseSettings(bodyData);
  };

  const columns: any[] = [
    {
      id: 'name',
      label: 'Course Name',
      minWidth: 100,
      sortable: true,
      field: 'course.name',
      format: (row: any) => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                color: (theme) => theme.palette.grey[500],
                mr: 2,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {row?.courseId?.name}
            </Typography>
          </Box>
        );
      },
    },
    {
      id: 'percentage',
      label: 'Assessment Passing Percentage',
      minWidth: 100,
      sortable: true,
      field: 'assessment.per',
      format: (row: any) => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                color: (theme) => theme.palette.grey[500],
                mr: 2,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {row?.assessmentPassingPer?.value}
            </Typography>
          </Box>
        );
      },
    },
    {
      flex: 0.06,
      minWidth: 100,
      sortable: false,
      id: 'action',
      label: 'Actions',
      field: 'actions',
      headerName: 'Actions',
      format: (row: any) => {
        return (
          <>
            <Tooltip title="Edit" arrow>
              <ModeEditIcon
                sx={{
                  cursor: 'pointer',
                  color: (theme) => theme.palette.grey[500],
                }}
                onClick={() => {
                  handleOpen(row);
                }}
              />
            </Tooltip>
          </>
        );
      },
    },
  ];

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const displayedData = courseSettingData?.slice(startIndex, endIndex) || [];

  const tabs = [
    {
      label: 'Course Seetings',
      component: (
        <Box maxWidth={{ sm: '100vw' }}>
          <CommonTableCourseSetting
            users={users}
            rows={displayedData}
            columns={columns}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            totalCount={courseSettingData?.length || 0}
            searchVal={searchVal}
            isPagination={true}
            sortMethod={(column: any) => {
              setSortField(column?.field);
              setSortOrder((prevSortOrder) =>
                column?.field === sortField && prevSortOrder === 'asc'
                  ? 'desc'
                  : 'asc'
              );
            }}
            sortColumn={sortField}
            sortOrder={sortOrder}
            isSearching={isCourseSettingFetching}
          />
        </Box>
      ),
    },
  ];

  return isCourseLoading && isCourseSettingLoading ? (
    <Loader />
  ) : (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h4" mb={3}>
        {textConst.courseSettings}
      </Typography>

      {courseList?.length > 0 && (
        <>
          <HookAutoComplete
            sx={{ width: '30%', py: 2 }}
            name="course"
            label={'Course'}
            control={control}
            options={courseList}
            size={'small'}
            getOptionLabel="name"
            isRequired={false}
            multiple={false}
            onChangeVal={(val: any) => {
              setSearchUserVal(val?._id);
              setSelectedCourseName(val?.name || null);
            }}
          />

          {selectedCourseName && (
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ width: '50%' }}>
                <Typography variant="h5" mt={2}>
                  {selectedCourseName}
                </Typography>
              </Box>

              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <HookTextField
                    control={control}
                    type="text"
                    name="assessmentPassPercentage"
                    label="Assessment Pass Percentage"
                    inputProps={{ maxLength: 3 }}
                  />
                </Grid>
              </Grid>
            </Box>
          )}

          <Stack direction={'row'} gap={2} justifyContent={'end'} mb={3}>
            <Button
              sx={{ width: { xs: '100%', sm: 'inherit' } }}
              variant="outlined"
              color="secondary"
              onClick={() => {
                navigate(-1);
              }}
            >
              {textConst.cancel}
            </Button>
            <Box
              sx={{
                cursor: 'not-allowed',
                width: { xs: '100%', sm: 'inherit' },
              }}
            >
              <LoadingButton
                variant="contained"
                color="secondary"
                type="submit"
                isLoading={updateIsLoading}
                disabled={updateIsLoading}
                sx={{ minWidth: '150px', width: { xs: '100%', sm: 'inherit' } }}
              >
                {textConst.save}
              </LoadingButton>
            </Box>
          </Stack>
        </>
      )}

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 4 }}>
        <Box
          sx={{
            width: {
              xs: '80%',
              sm: '50%',
            },
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <SearchBarReport
            placeHolder="Search Course Name"
            size="small"
            onKeyUp={(value: string) => {
              setSearchVal?.(value.trim());
            }}
            handleClose={() => setSearchVal('')}
          />
        </Box>
      </Box>

      {/* <CustomTabs tabs={tabs} /> */}

      <Box maxWidth={{ sm: '100vw' }}>
        <CommonTableCourseSetting
          users={users}
          rows={displayedData}
          columns={columns}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          totalCount={courseSettingData?.length || 0}
          searchVal={searchVal}
          isPagination={true}
          sortMethod={(column: any) => {
            setSortField(column?.field);
            setSortOrder((prevSortOrder) =>
              column?.field === sortField && prevSortOrder === 'asc'
                ? 'desc'
                : 'asc'
            );
          }}
          sortColumn={sortField}
          sortOrder={sortOrder}
          isSearching={isCourseSettingFetching}
        />
      </Box>

      {openDialog && (
        <AlertSettingDialog
          open={openDialog}
          handleClose={handleClose}
          refetch={refetch}
          // handleSave={handleSendRemiderMail}
          courseEditId={courseEditId}
          title={selectedCourseNameEdit}
          content={courseEditData}
          // isLoading={isSendCourseRemainderLoading}
          saveBtnText={textConst.save}
        />
      )}
    </form>
  );
};

export default Settings;
