import React from 'react';

import GroupsIcon from '@mui/icons-material/Groups';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

import CompletedIcon from '../assets/svg/completed.svg';
import InProgressIcon from '../assets/svg/in-progress.svg';
import VideoIcon from '../assets/svg/video.svg';
import { commonColors } from '../theme-loader/theme/color';
export const drawerWidth = 256;

type userRole = 'manager' | 'admin' | 'user';

type visibility = {
  [key: string]: boolean;
  manager: boolean;
  admin: boolean;
  user: boolean;
};

type attr = {
  visibility: visibility;
};

export const role: userRole = 'manager';

interface DrawerItem {
  title: string;
  path: string;
  icon: React.JSX.Element;
  subItems?: DrawerItem[];
  attr: attr;
}
export const drawerItem: DrawerItem[] = [
  {
    title: 'Home',
    path: '/home',
    icon: <HomeIcon />,
    attr: { visibility: { admin: true, manager: true, user: true } },
  },
  {
    title: 'Users',
    path: '/users/list',
    icon: <PersonIcon />,
    attr: { visibility: { admin: true, manager: false, user: false } },
  },
  {
    title: 'My Team',
    path: '/my-team',
    icon: <GroupsIcon />,
    attr: { visibility: { admin: false, manager: true, user: false } },
  },
  // {
  //   title: 'Profile',
  //   path: '/profile',
  //   icon: <PersonIcon />,
  //   attr: { visibility: { admin: true, manager: true, user: true } },
  // },
  // {
  //   title: 'Settings',
  //   path: '/settings',
  //   icon: <SettingsIcon />,
  //   attr: { visibility: { admin: true, manager: false, user: false } },
  //   subItems: [
  //    {
  //      title: 'Course Setting',
  //      path: '/settings/course-setting',
  //      icon: <FormatListBulletedIcon />,
  //      attr: { visibility: { admin: true, manager: false, user: false } },
  //    },
  //    {
  //     title: 'Dashboard Setting',
  //     path: '/settings/dashboard-setting',
  //     icon: <FormatListBulletedIcon />,
  //     attr: { visibility: { admin: true, manager: false, user: false } },
  //   },
  //  ],
  // },
  // {
  //   title: 'Report',
  //   path: '/report',
  //   icon: <BarChartIcon />,
  //   attr: { visibility: { admin: true, manager: false, user: false } },
  //    subItems: [
  //     {
  //       title: 'Course Graph',
  //       path: '/report/course',
  //       icon: <FormatListBulletedIcon />,
  //       attr: { visibility: { admin: true, manager: false, user: false } },
  //     },
  //     {
  //       title: 'User Course Graph',
  //       path: '/report/user-course',
  //       icon: <LocationCityIcon />,
  //       attr: { visibility: { admin: true, manager: false, user: false } },
  //     },
  //   ],
  // },
  // {
  //   title: 'Assessment',
  //   path: '/assessment',
  //   icon: <PersonIcon />,
  //   attr: { visibility: { admin: true, manager: false, user: false } },
  //   subItems: [
  //     {
  //       title: 'List',
  //       path: '/assessment/list',
  //       icon: <FormatListBulletedIcon />,
  //       attr: { visibility: { admin: true, manager: false, user: false } },
  //     },
  //     {
  //       title: 'Categories',
  //       path: '/assessment/categories',
  //       icon: <LocationCityIcon />,
  //       attr: { visibility: { admin: true, manager: false, user: false } },
  //     },
  //   ],
  // },
  // {
  //   title: 'Learning',
  //   path: '/learning',
  //   icon: <SchoolIcon />,
  //   attr: { visibility: { admin: false, manager: true, user: true } },
  // },
  // {
  //   title: 'Courses',
  //   path: '/courses',
  //   icon: <SchoolIcon />,
  //   attr: { visibility: { admin: true, manager: false, user: false } },
  //   subItems: [
  //     {
  //       title: 'List',
  //       path: '/courses/list',
  //       icon: <FormatListBulletedIcon />,
  //       attr: { visibility: { admin: true, manager: false, user: false } },
  //     },
  //     {
  //       title: 'Categories',
  //       path: '/courses/categories',
  //       icon: <LocationCityIcon />,
  //       attr: { visibility: { admin: true, manager: false, user: false } },
  //     },
  //   ],
  // },
];

export const statusList = [
  {
    _id: 0,
    name: 'Active',
    value: true,
  },
  {
    _id: 1,
    name: 'Inactive',
    value: false,
  },
];

export const directList = [
  {
    _id: 0,
    name: 'Direct',
    value: true,
  },
  {
    _id: 1,
    name: 'Indirect',
    value: false,
  },
];

export const chartColors: any = {
  'Not Yet Started': commonColors.brightRed,
  'In Progress': commonColors.orange,
  'Completed': commonColors.green,
  'Recompletion': commonColors.pink,
};

export const iconMap: any = {
  'Not Yet Started': VideoIcon,
  'In Progress': InProgressIcon,
  'Completed': CompletedIcon,
  'Recompletion': null,
};

export const btnIconMap: any = {
  'Not Yet Started': PlayArrowIcon,
  'In Progress': PlayCircleIcon,
  'Completed': RemoveRedEyeIcon,
  'Recompletion': PlayArrowIcon,
};

export const chartData = [
  {
    _id: 1,
    color: commonColors.brightRed,
    text: 'Not Yet Started',
    value: 1,
    icon: VideoIcon,
    btnName: 'Start',
    btnIcon: PlayArrowIcon,
  },
  {
    _id: 2,
    color: commonColors.orange,
    text: 'In Progress',
    value: 1,
    icon: InProgressIcon,
    btnName: 'Resume',
    btnIcon: PlayCircleIcon,
  },
  {
    _id: 3,
    color: commonColors.green,
    text: 'Completed',
    value: 1,
    icon: CompletedIcon,
    btnName: 'View',
    btnIcon: RemoveRedEyeIcon,
  },
  {
    _id: 4,
    color: commonColors.pink,
    text: 'Recompletion',
    value: 1,
    btnName: 'Start',
    btnIcon: PlayArrowIcon,
  },
];

export let slideInterval = 0;

export const updateInterval = (interval: any) => {
  slideInterval = interval;
};

export const sectionAssessmentViewed: any[] = [];

export const slideDuration = {
  slide: 0,
  duration: 0,
};

export let lastWatchedVideoTime: any = 0;

export const updateLastWatchedVideoTime = (time: any) => {
  lastWatchedVideoTime = time;
};

export const setIsAssessmentOpened = (id: any) => {
  sectionAssessmentViewed.push(id);
};
