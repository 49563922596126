import React, { useEffect, useState } from 'react';

// import MoreVertIcon from '@mui/icons-material/MoreVert';
import { yupResolver } from '@hookform/resolvers/yup';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { BlobProvider } from '@react-pdf/renderer';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import CommonTableUser from '../../../components/CommonTableUser';
import CustomChip from '../../../components/CustomChip';
import { GenerateCertificatePdf } from '../../../components/generate-pdf/GenerateCertificatePdf';
import HookAutoComplete from '../../../components/HookAutoComplete';
import Loader from '../../../components/Loader';
import PieChart from '../../../components/PieChart';
import PieChartForCourse from '../../../components/PieChartForCourse/PieChartForCourse';
import SearchBarReport from '../../../components/SearchBarReport';
import { useGetAllCoursesQuery } from '../../../store/apis/courses';
import { useGetAllUserCourseQuery } from '../../../store/apis/user';
import { userSelector } from '../../../store/user';
import { convertChartData, notify } from '../../../utils/commonFunctions';
import { chartData } from '../../../utils/constant';
import textConst from '../../../utils/textConst';

const ReportCourse = () => {
  const user = useSelector(userSelector);

  const validationSchema = yup.object({});

  const [searchVal, setSearchVal] = useState<any>(null);
  const [searchCourseVal, setSearchCourseVal] = useState<any>(null);
  const [sortField, setSortField] = useState<any>(null);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');

  const { control } = useForm<any>({
    resolver: yupResolver(validationSchema),
  });

  const navigate = useNavigate();

  const {
    data: courseData,
    isLoading: isCourseLoading,
    isError: isCourseError,
    error: courseError,
    isFetching,
  } = useGetAllCoursesQuery();

  useEffect(() => {
    if (isCourseError) {
      notify(courseError?.data?.message, 'error', 1200);
    }
  }, [courseError?.data?.message, isCourseError]);

  const { data: userCourses, isLoading: userCoursesLoading } =
    useGetAllUserCourseQuery();

  interface User {
    _id: string;
    firstName: string;
    lastName: string;
  }

  interface Course {
    _id: string;
    name: string;
  }

  const userList: User[] = [];
  const courseList: Course[] = [];

  courseData?.courses.map((course: any) => {
    if (course?.isDraft === false) {
      courseList.push({
        _id: course._id,
        name: course.name,
      });
    }
    return [];
  });

  const filteredUsers = userCourses?.courses
    // .filter((data: any) =>
    //     data?.user && !data?.user?.deletedBy && data?.status !== 'Recompletion'
    // )
    .map((data: any) => ({
      _id: data?.user?._id,
      name: data?.user?.firstName + ' ' + data?.user?.lastName,
    }));

  if (filteredUsers) {
    filteredUsers.forEach((user: any) => {
      const isDuplicate = userList.some(
        (existingUser: any) => existingUser.name === user.name
      );

      if (!isDuplicate) {
        userList.push(user);
      }
    });
  }

  const allCourseName: string[] = [];

  courseData?.courses.map((name: any) => {
    name.isDraft !== true && allCourseName.push(name.name);
  });

  const homeCourseColumns: any[] = [
    {
      id: 'learning',
      label: 'Learning',
      field: 'learning',
      format: (row: any) => {
        return (
          <Box display={'flex'} alignItems={'center'} gap={1}>
            <Typography
              variant="subtitle2"
              sx={{
                color: (theme) => theme.palette.grey[500],
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {row?.course?.name}
            </Typography>
            {row?.completedAt && row?.status === 'Completed' ? (
              <BlobProvider
                document={
                  <GenerateCertificatePdf
                    userName={`${user?.firstName} ${user?.lastName}`}
                    courseName={row?.course?.name}
                    courseCompletionDate={row?.completedAt}
                  />
                }
              >
                {({ url }) => (
                  <a
                    href={url || ''}
                    target="_blank"
                    rel="noreferrer"
                    onClick={(e: any) => e.stopPropagation()}
                  >
                    <WorkspacePremiumIcon color="primary" />
                  </a>
                )}
              </BlobProvider>
            ) : null}
          </Box>
        );
      },
    },
    {
      id: 'status',
      label: 'Status',
      minWidth: 130,
      field: 'status',
      format: (row: any) => {
        const data = chartData.find((item) => item.text === row.status);
        if (!data) {
          return null;
        }
        return (
          <CustomChip
            label={row.status}
            color="primary"
            sx={{
              background: data.color,
            }}
          />
        );
      },
    },
    {
      id: 'due',
      label: 'Due',
      field: 'due',
      minWidth: 150,
      format: (row: any) =>
        row?.dueAt ? moment(row.dueAt).format('DD/MM/YYYY') : '-',
    },
  ];

  if (isCourseLoading) {
    return <Loader />;
  }

  return (
    <Grid>
      <Typography variant="h4" mb={3}>
        {textConst.report}
      </Typography>

      {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box sx={{
                    width: {
                        xs: '80%',
                        sm: '20%'
                    },
                    display: 'flex', alignItems: 'center', mr: 4, mb: 4
                }}>
                    <SearchBarReport
                        placeHolder="Search"
                        size="small"
                        onKeyUp={(value: string) => {
                            setSearchVal?.(value.trim());
                        }}
                        handleClose={() => setSearchVal('')}
                    />
                </Box>

                <HookAutoComplete
                    sx={{ width: '20%', mr: 4 }}
                    name="course"
                    label={'Course'}
                    control={control}
                    options={courseList}
                    size={'small'}
                    getOptionLabel="name"
                    isRequired={false}
                    multiple={false}
                    onChangeVal={(val: any) => {
                        setSearchCourseVal(val?.name);
                    }}
                />
            </Box> */}

      <Grid
        container
        item
        xs={12}
        lg={12}
        xl={12}
        spacing={2}
        justifyContent="flex-end"
        alignItems={'center'}
        sx={{ mb: 4 }}
      >
        <Grid item xs={12} sm={4} md={3}>
          <SearchBarReport
            placeHolder="Search"
            size="small"
            onKeyUp={(value: string) => {
              setSearchVal?.(value.trim());
            }}
            handleClose={() => setSearchVal('')}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3.5}>
          <HookAutoComplete
            name="course"
            label={'Course'}
            control={control}
            options={courseList}
            size={'small'}
            getOptionLabel="name"
            isRequired={false}
            multiple={false}
            onChangeVal={(val: any) => {
              setSearchCourseVal(val?.name);
            }}
          />
        </Grid>
      </Grid>

      <Grid
        container
        gap={'24px'}
        sx={{
          display: 'flex',
          justifyContent: {
            xs: 'center',
            lg: 'center',
            md: 'center',
            xl: 'flex-start',
            sm: 'center',
          },
        }}
      >
        {user?.role !== 'Admin' ? (
          <Grid container spacing={'24px'}>
            <Grid item xs={12} lg={6}>
              <CommonTableUser
                rows={courseData?.courses}
                columns={homeCourseColumns}
                page={0}
                rowsPerPage={5}
                onPageChange={() => {}}
                onRowsPerPageChange={() => {}}
                totalCount={courseData?.courses?.length}
                isSearching={isFetching}
                isPagination={false}
                handleClick={(e, row) => {
                  navigate(`/learning/course/${row?.course?._id}`);
                }}
                sx={{
                  '&:last-child td': {
                    borderBottom: 0,
                  },
                }}
                sortMethod={(column: any) => {
                  setSortField(column?.field);
                  setSortOrder((prevSortOrder) =>
                    column?.field === sortField && prevSortOrder === 'asc'
                      ? 'desc'
                      : 'asc'
                  );
                }}
                sortColumn={sortField}
                sortOrder={sortOrder}
              />
            </Grid>
            <Grid
              item
              xs={12}
              lg={6}
              sx={{
                borderRadius: '16px',
                overflow: 'hidden',
              }}
            >
              <Box
                sx={{
                  borderRadius: '16px',
                  boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.10)',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    borderTopRightRadius: '16px',
                    borderTopLeftRadius: '16px',
                    p: '12px 16px',
                    backgroundColor: (theme) => theme.palette.grey[500],
                  }}
                >
                  <Typography
                    sx={{
                      color: (theme) => theme.palette.grey[50],
                      fontWeight: 500,
                    }}
                    variant="subtitle2"
                  >
                    {textConst.report}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    borderBottomRightRadius: '16px',
                    borderBottomLeftRadius: '16px',
                    py: 3,
                    backgroundColor: (theme) => theme.palette.grey[50],
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {courseData?.courses?.length ? (
                    <PieChart
                      chartData={convertChartData(courseData?.courses)}
                    />
                  ) : (
                    <Typography sx={{ fontSize: 14 }}>
                      No data available
                    </Typography>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        ) : null}

        {userCoursesLoading ? (
          <Loader />
        ) : user?.role === 'Admin' ? (
          <>
            {allCourseName.map((courseName) => (
              <PieChartForCourse
                key={courseName}
                userCourses={userCourses}
                targetCourseName={courseName}
                searchVal={searchVal}
                setSearchVal={setSearchVal}
                searchCourseVal={searchCourseVal}
              />
            ))}
          </>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default ReportCourse;
