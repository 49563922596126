import React, { useEffect, useMemo, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';

import LoadingButton from './LoadingButton';
import {
  useUpdateUserMutation,
  useUpdateUserSummaryMutation,
} from '../store/apis/user';
import { userSelector } from '../store/user';
import { notify } from '../utils/commonFunctions';
import textConst from '../utils/textConst';

interface HookTextAreaProps {
  rows?: number;
  selectedProfile: any;
  userSummaryData: any;
  [key: string]: any;
}

const validationSchema = yup.object({
  summary: yup.array().required(),
});

const HookTextSummary: React.FC<HookTextAreaProps> = ({
  rows,
  selectedProfile,
  userSummaryData,
  ...rest
}) => {
  const location = useLocation();

  const param = useParams();

  const [
    updateUserPost,
    {
      isLoading: isUpdateLoading,
      isError: isUpdateError,
      error: updateError,
      isSuccess: isUpdateSuccess,
    },
  ] = useUpdateUserMutation();

  useEffect(() => {
    if (isUpdateSuccess) {
      notify('Summary added successfully', 'success', 1200);
      setEditMode(false);
    }
  }, [isUpdateSuccess]);

  const isProfilePage = useMemo(
    () => location.pathname.includes('profile'),
    [location.pathname]
  );

  useEffect(() => {
    if (isUpdateError) {
      notify(updateError?.data?.message, 'error', 1200);
    }
  }, [isUpdateError, updateError]);

  const { control, watch, setValue, handleSubmit } = useForm({
    defaultValues: {
      summary: '',
    },
    resolver: yupResolver(
      yup.object({
        summary: yup.string().required('Summary is required'),
      })
    ),
  });

  useEffect(() => {
    // Find the first summary that is not 'undefined'
    const validSummary = userSummaryData.find(
      (item: any) => item?.summary && item.summary !== 'undefined'
    )?.summary;

    // If a valid summary is found, set the summary value in the form
    if (validSummary) {
      setValue('summary', validSummary.substring(0, 1000));
    }
  }, [userSummaryData, setValue]);

  const summary = watch('summary');
  const user = useSelector(userSelector);

  const isAdminRole = user?.role === 'Admin';

  const [isEditMode, setEditMode] = useState(false);
  const remainingCount = useMemo(
    () => 1000 - (summary?.length || 0),
    [summary?.length]
  );

  // const onSubmit = (data: any) => {
  //   const body = {
  //     summary: data?.summary,
  //   };

  //   updateUserPost({
  //     // id: isProfilePage ? selectedProfile?._id : param?.userId,
  //     id: selectedProfile?._id,
  //     ...body,
  //   });
  // };

  const onSubmit = (data: any) => {
    const body = {
      summary: data.summary,
    };

    updateUserPost({
      id: selectedProfile?._id,
      ...body,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box py={2}>
        <Box
          sx={{
            backgroundColor: (theme) => theme.palette.grey[500],
            borderRadius: '20px 20px 0 0',
            minHeight: '50px',
          }}
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          px={2}
          py={1}
        >
          <Typography
            variant="subtitle2"
            sx={{
              color: 'white',
            }}
          >
            Summary
          </Typography>
          {!isEditMode && isAdminRole && !isUpdateLoading && !param?.id ? (
            <Button
              startIcon={<EditIcon fontSize={'small'} />}
              onClick={() => {
                setEditMode(true);
                // Clear the value of the summary field
                setValue('summary', '');
              }}
              variant="contained"
              color="inherit"
              sx={{
                p: '3px 10px',
                backgroundColor: '#fff',
                color: (theme: any) => theme.palette.grey[500],
              }}
            >
              {textConst.add}
            </Button>
          ) : null}
        </Box>
        <Controller
          name="summary"
          control={control}
          render={({ field }: any) => (
            <TextField
              {...field}
              fullWidth
              multiline
              placeholder={
                user?.role !== 'Admin' && !summary?.length
                  ? '-'
                  : selectedProfile?.role?.name !== 'Admin'
                  ? 'Please enter a short summary'
                  : 'Please enter some information about yourself.'
              }
              rows={rows ?? undefined}
              disabled={!isEditMode}
              inputProps={{ maxLength: 1000 }}
              {...rest}
              sx={{
                '& .MuiInputBase-root': {
                  'borderRadius': '0 0 20px 20px',
                  'boxShadow': '0px 4px 10px 0px #0000001A',
                  '& fieldset': {
                    borderColor: 'transparent',
                    borderWidth: 0,
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'transparent',
                    borderWidth: 0,
                  },
                },
                '& .MuiOutlinedInput-input.Mui-disabled': {
                  WebkitTextFillColor: '#000',
                },
              }}
            />
          )}
        />
      </Box>
      {isEditMode && isAdminRole ? (
        <Box
          display={'flex'}
          sx={{
            flexDirection: { xs: 'column', sm: 'row', md: 'column', lg: 'row' },
          }}
          justifyContent={'space-between'}
        >
          <Typography
            variant="subtitle2"
            sx={{ color: (theme) => theme.palette.grey[500] }}
          >
            {remainingCount} Character{remainingCount > 0 ? '(s)' : ''}{' '}
            Remaining
          </Typography>

          <Box
            display={'flex'}
            sx={{
              my: { xs: 2, md: 0 },
              justifyContent: { xs: 'start', md: 'end' },
            }}
          >
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                setEditMode(false);
                setValue(
                  'summary',
                  userSummaryData[0]?.summary?.substring(0, 1000) || ''
                );
              }}
              sx={{
                width: { xs: '100%', sm: 'auto' },
                p: '3px 10px',
              }}
            >
              {textConst.cancel}
            </Button>
            <LoadingButton
              disabled={!summary || summary.length === 0}
              isLoading={isUpdateLoading}
              type="submit"
              variant="contained"
              sx={{
                py: '3px',
                ml: 2,
              }}
            >
              {textConst.add}
            </LoadingButton>
          </Box>
        </Box>
      ) : null}
    </form>
  );
};

export default HookTextSummary;
