import { createApiInstance } from './createApiInstance';
import {
  queryParamsBuilder,
  updateCacheData,
} from '../../utils/commonFunctions';

const extendedApi: any = createApiInstance.injectEndpoints({
  endpoints: (build) => ({
    changePassword: build.mutation({
      query({ id, ...body }) {
        return {
          url: `/api/users/change-password/${id}`,
          method: 'POST',
          body,
        };
      },
      transformResponse: (res) => {
        return res.data;
      },
    }),
    getAllUser: build.query({
      query: (query) => `/api/users${queryParamsBuilder(query)}`,
      transformResponse: (res) => {
        return res.data;
      },
    }),
    getAllUserCourse: build.query({
      query: (query) =>
        `/api/courses/getallcourses${queryParamsBuilder(query)}`,
      transformResponse: (res) => {
        return res.data;
      },
    }),
    sendMail: build.query({
      query: (id) => `/api/users/send-welcome-mail/${id}`,
      transformResponse: (res) => {
        return res.data;
      },
    }),
    sendWelcomeMail: build.query({
      // query: (id) => `/api/users/send-welcome-mail-new/${id}`,
      query: (id) => `/api/users/send-activate/${id}`,
      transformResponse: (res) => {
        return res.data;
      },
    }),
    getUser: build.query({
      query: (id) => `/api/users/${id}`,
      transformResponse: (res) => {
        return res.data;
      },
      providesTags: ['GetUser'],
    }),
    getUserSummary: build.query({
      query: (id) => `/api/users/summary/${id}`,
      transformResponse: (res) => {
        return res;
      },
      providesTags: ['GetUser'],
    }),
    importUser: build.mutation({
      query(body) {
        const formData = new FormData();
        Object.keys(body).forEach((key) => {
          formData.append(key, body[key]);
        });

        return {
          url: '/api/users/uploadexcel',
          method: 'POST',
          body: formData,
        };
      },
      async onQueryStarted(patch, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;

          const updateCache = (method: any, cb: any) => {
            updateCacheData(
              {
                state: getState(),
                method,
                dispatch,
                extendedApi,
              },
              cb
            );
          };

          updateCache('getAllUser', (draft: any) => {
            const index = draft.users.findIndex(
              (item: any) => item._id === data._id
            );
            if (index !== -1) {
              draft.users.splice(index, 1, data);
            } else {
              draft.users.push(data);
            }
          });

          updateCache('getTeamMembers', (draft: any) => {
            const index = draft.teamMembers.findIndex(
              (item: any) => item._id === data._id
            );
            if (index !== -1) {
              draft.teamMembers.splice(index, 1, data);
            } else {
              draft.teamMembers.push(data);
            }
          });
        } catch (error) {
          return;
        }
      },
      transformResponse: (res) => {
        return res.data;
      },
    }),
    addUser: build.mutation({
      query(body) {
        const formData = new FormData();
        Object.keys(body).forEach((key) => {
          formData.append(key, body[key]);
        });

        return {
          url: '/api/users',
          method: 'POST',
          body: formData,
        };
      },
      async onQueryStarted(patch, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;

          const updateCache = (method: any, cb: any) => {
            updateCacheData(
              {
                state: getState(),
                method,
                dispatch,
                extendedApi,
              },
              cb
            );
          };

          updateCache('getAllUser', (draft: any) => {
            const index = draft.users.findIndex(
              (item: any) => item._id === data._id
            );
            if (index !== -1) {
              draft.users.splice(index, 1, data);
            } else {
              draft.users.push(data);
            }
          });

          updateCache('getTeamMembers', (draft: any) => {
            const index = draft.teamMembers.findIndex(
              (item: any) => item._id === data._id
            );
            if (index !== -1) {
              draft.teamMembers.splice(index, 1, data);
            } else {
              draft.teamMembers.push(data);
            }
          });
        } catch (error) {
          return;
        }
      },
      transformResponse: (res) => {
        return res.data;
      },
    }),
    deleteUser: build.mutation({
      query(id) {
        return {
          url: `/api/users/${id}`,
          method: 'DELETE',
        };
      },
      async onQueryStarted(patch, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const updateCache = (method: any, cb: any) => {
            updateCacheData(
              {
                state: getState(),
                method,
                dispatch,
                extendedApi,
              },
              cb
            );
          };

          updateCache('getAllUser', (draft: any) => {
            const index = draft.users.findIndex(
              (item: any) => item._id === data?._id || patch
            );
            draft.users.splice(index, 1);
            draft.pagination.totalCount = draft.pagination.totalCount - 1;
          });

          updateCache('getTeamMembers', (draft: any) => {
            const index = draft.teamMembers.findIndex(
              (item: any) => item._id === data?._id || patch
            );
            draft.teamMembers.splice(index, 1);
            draft.pagination.totalCount = draft.pagination.totalCount - 1;
          });
        } catch (error) {
          return;
        }
      },
      transformResponse: (res) => {
        return res.data;
      },
    }),
    deleteUserSummary: build.mutation({
      query(args: { id: string; summaryId: string }) {
        const { id, summaryId } = args;
        return {
          url: `/api/users/delete-summary/${id}/${summaryId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['GetUser'],
      async onQueryStarted(patch, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const updateCache = (method: any, cb: any) => {
            updateCacheData(
              {
                state: getState(),
                method,
                dispatch,
                extendedApi,
              },
              cb
            );
          };

          updateCache('getUser', (draft: any) => {
            Object.assign(draft, data);
          });

          updateCache('getAllUser', (draft: any) => {
            const index = draft.users.findIndex(
              (item: any) => item._id === data._id
            );
            if (index !== -1) {
              draft.users.splice(index, 1, data);
            } else {
              draft.users.push(data);
            }
          });

          updateCache('getTeamMembers', (draft: any) => {
            const index = draft.teamMembers.findIndex((item: any) => {
              return item?.user._id === data._id;
            });
            if (index !== -1) {
              draft.teamMembers[index].user = data;
            }
          });
        } catch (error) {
          return;
        }
      },
      transformResponse: (res) => {
        return res.data;
      },
    }),
    updateUser: build.mutation({
      query({ id, ...body }) {
        const formData = new FormData();
        Object.keys(body).forEach((key) => {
          formData.append(key, body[key]);
        });
        return {
          url: `/api/users/${id}`,
          method: 'PUT',
          body: formData,
        };
      },
      invalidatesTags: ['GetUser'],
      async onQueryStarted(patch, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const updateCache = (method: any, cb: any) => {
            updateCacheData(
              {
                state: getState(),
                method,
                dispatch,
                extendedApi,
              },
              cb
            );
          };

          updateCache('getUser', (draft: any) => {
            Object.assign(draft, data);
          });

          updateCache('getAllUser', (draft: any) => {
            const index = draft.users.findIndex(
              (item: any) => item._id === data._id
            );
            if (index !== -1) {
              draft.users.splice(index, 1, data);
            } else {
              draft.users.push(data);
            }
          });

          updateCache('getTeamMembers', (draft: any) => {
            const index = draft.teamMembers.findIndex((item: any) => {
              return item?.user._id === data._id;
            });
            if (index !== -1) {
              draft.teamMembers[index].user = data;
            }
          });
        } catch (error) {
          return;
        }
      },
      transformResponse: (res) => {
        return res.data;
      },
    }),
    assignUserCourse: build.mutation({
      query(body) {
        return {
          url: '/api/users/course-assign',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['GetUser'],
      async onQueryStarted(patch, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const updateCache = (method: any, cb: any) => {
            updateCacheData(
              {
                state: getState(),
                method,
                dispatch,
                extendedApi,
              },
              cb
            );
          };

          updateCache('getUser', (draft: any) => {
            Object.assign(draft, data);
          });

          updateCache('getAllUser', (draft: any) => {
            const index = draft.users.findIndex(
              (item: any) => item._id === data._id
            );
            if (index !== -1) {
              draft.users.splice(index, 1, data);
            } else {
              draft.users.push(data);
            }
          });

          updateCache('getTeamMembers', (draft: any) => {
            const index = draft.teamMembers.findIndex((item: any) => {
              return item?.user._id === data._id;
            });
            if (index !== -1) {
              draft.teamMembers[index].user = data;
            }
          });
        } catch (error) {
          return;
        }
      },
      transformResponse: (res) => {
        return res.data;
      },
    }),
    updateUserSummary: build.mutation({
      query({ id, summaryId, ...body }) {
        return {
          url: `/api/users/update-summary/${id}/${summaryId}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: ['GetUser'],
      async onQueryStarted(patch, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const updateCache = (method: any, cb: any) => {
            updateCacheData(
              {
                state: getState(),
                method,
                dispatch,
                extendedApi,
              },
              cb
            );
          };

          updateCache('getUser', (draft: any) => {
            Object.assign(draft, data);
          });

          updateCache('getAllUser', (draft: any) => {
            const index = draft.users.findIndex(
              (item: any) => item._id === data._id
            );
            if (index !== -1) {
              draft.users.splice(index, 1, data);
            } else {
              draft.users.push(data);
            }
          });

          updateCache('getTeamMembers', (draft: any) => {
            const index = draft.teamMembers.findIndex((item: any) => {
              return item?.user._id === data._id;
            });
            if (index !== -1) {
              draft.teamMembers[index].user = data;
            }
          });
        } catch (error) {
          return;
        }
      },
      transformResponse: (res) => {
        return res.data;
      },
    }),
    updateUserProfilePhoto: build.mutation({
      query({ id, ...body }) {
        const formData = new FormData();
        Object.keys(body).forEach((key) => {
          formData.append(key, body[key]);
        });
        return {
          url: `/api/users/update-image/${id}`,
          method: 'PUT',
          body: formData,
        };
      },
      invalidatesTags: ['GetUser'],
      async onQueryStarted(patch, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const updateCache = (method: any, cb: any) => {
            updateCacheData(
              {
                state: getState(),
                method,
                dispatch,
                extendedApi,
              },
              cb
            );
          };

          updateCache('getUser', (draft: any) => {
            Object.assign(draft, data);
          });

          updateCache('getAllUser', (draft: any) => {
            const index = draft.users.findIndex(
              (item: any) => item._id === data._id
            );
            if (index !== -1) {
              draft.users.splice(index, 1, data);
            } else {
              draft.users.push(data);
            }
          });

          updateCache('getTeamMembers', (draft: any) => {
            const index = draft.teamMembers.findIndex((item: any) => {
              return item?.user._id === data._id;
            });
            if (index !== -1) {
              draft.teamMembers[index].user = data;
            }
          });
        } catch (error) {
          return;
        }
      },
      transformResponse: (res) => {
        return res.data;
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useChangePasswordMutation,
  useLazySendMailQuery,
  useLazySendWelcomeMailQuery,
  useGetAllUserQuery,
  useGetAllUserCourseQuery,
  useGetUserQuery,
  useAssignUserCourseMutation,
  useGetUserSummaryQuery,
  useImportUserMutation,
  useAddUserMutation,
  useDeleteUserMutation,
  useDeleteUserSummaryMutation,
  useUpdateUserMutation,
  useUpdateUserSummaryMutation,
  useUpdateUserProfilePhotoMutation,
} = extendedApi;
