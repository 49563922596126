import React from 'react';

import { List, ListItem } from '@mui/material';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import moment from 'moment';

import {
  capitalizeFirstLetter,
  getFullName,
} from '../../utils/commonFunctions';
import { chartData } from '../../utils/constant';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: 'white',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: 2,
  },
  containerList: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  tableContainer: {
    marginTop: 10,
    flexDirection: 'row',
  },
  tableHeaderCell: {
    width: '30%',
    textAlign: 'left',
    fontWeight: 'bold',
  },
  tableHeaderCell1: {
    width: '70%',
    textAlign: 'left',
    fontWeight: 'bold',
  },
  tableRowCell: {
    width: '70%',
    textAlign: 'left',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  tableRowCell1: {
    width: '170%',
    textAlign: 'left',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  fontSize14: {
    fontSize: '14px',
  },
  fontSize12: {
    fontSize: '12px',
  },
  fontSize10: {
    fontSize: '10px',
  },
  tHead: {
    fontSize: '12px',
    padding: '8px 10px',
    borderRadius: '8px',
    color: 'white',
    backgroundColor: '#3C4764',
  },
  tRow: {
    fontSize: '10px',
    padding: '0px 10px',
  },
  mt5: {
    marginTop: '5px',
  },
  mb5: {
    marginBottom: '5px',
  },
  mt10: {
    marginTop: '10px',
  },
  mt20: {
    marginTop: '20px',
  },
  chip: {
    color: 'white',
    borderRadius: '16px',
    padding: '2px 7px',
  },
  flexStart: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  lightColor: {
    color: '#526189',
  },
  card: {
    backgroundColor: '#f2f2f2',
    padding: '10px',
    borderRadius: '8px',
  },
  mainHeader: {
    border: '2px dashed #526189',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 5px',
    width: '90px',
    height: '90px',
    borderRadius: '10px',
  },
  width100: {
    maxHeight: '100px',
    overflow: 'hidden',
  },
  colorBack: {
    color: 'white',
    backgroundColor: '#526189',
  },
});

const truncateText = (text: string, length: number) => {
  return text?.length > length ? text?.substring(0, length) + '...' : text;
};

// Create Document Component
export const GenerateProfilePdf = ({
  selectedProfile,
  courseData,
  userSummaryData,
}: any) => {
  const isAdminRole = selectedProfile?.role?.name === 'Admin';
  const isManager =
    selectedProfile?.managers?.length || selectedProfile?.manager?.firstName;

  const isSummary =
    selectedProfile?.summary?.length || selectedProfile?.user?.summary?.length;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={[styles.container, { gap: 10 }]}>
            <View
              style={[
                styles.mainHeader,
                !selectedProfile?.image ? styles.colorBack : {},
              ]}
            >
              {selectedProfile?.image ? (
                <Image
                  src={`${selectedProfile?.image}?${Date.now()}`}
                  style={{ objectFit: 'contain' }}
                />
              ) : (
                <Text>
                  {selectedProfile?.firstName?.charAt(0).toUpperCase() ||
                    selectedProfile?.user?.firstName?.charAt(0).toUpperCase()}
                </Text>
              )}
            </View>
            <View style={styles.mt5}>
              <View style={[styles.container]}>
                <Text>
                  {capitalizeFirstLetter(selectedProfile?.firstName) ||
                    capitalizeFirstLetter(
                      selectedProfile?.user?.firstName
                    )}{' '}
                  {capitalizeFirstLetter(selectedProfile?.lastName) ||
                    capitalizeFirstLetter(selectedProfile?.user?.lastName)}
                </Text>
              </View>
              {!isAdminRole && (
                <View style={[styles.fontSize12, styles.mt10]}>
                  {isManager && (
                    <View style={styles.container}>
                      <Text style={styles.lightColor}>Manager:</Text>
                      <Text>
                        {getFullName(selectedProfile?.managers)?.toString() ??
                          capitalizeFirstLetter(
                            selectedProfile?.manager?.firstName
                          ) +
                            ' ' +
                            capitalizeFirstLetter(
                              selectedProfile?.manager?.lastName
                            )}
                      </Text>
                    </View>
                  )}
                  {/* <View style={[styles.container, styles.mt5]}>
                    <Text style={styles.lightColor}>Category:</Text>
                    <Text>
                      {selectedProfile?.categories?.map(
                        (val: any, index: number) => {
                          return (
                            <React.Fragment key={val?._id}>
                              {index > 0 && ', '}
                              {val?.name}
                            </React.Fragment>
                          );
                        }
                      )}
                    </Text>
                  </View> */}
                  <View style={[styles.container, styles.mt5]}>
                    <Text style={styles.lightColor}>Category:</Text>
                    <View style={styles.containerList}>
                      {selectedProfile?.categories?.map((val: any) => (
                        <View key={val?._id} style={styles.container}>
                          <Text>{val?.name}</Text>
                        </View>
                      ))}
                    </View>
                  </View>
                </View>
              )}
            </View>
          </View>
          {/* {isSummary && (
            <View style={[styles.fontSize12, styles.mt10, styles.card]}>
              <Text>Summary</Text>
              <Text
                style={[
                  styles.mt5,
                  styles.lightColor,
                  styles.fontSize10,
                  styles.width100,
                ]}
              >
                {selectedProfile?.summary || selectedProfile?.user?.summary}
              </Text>
            </View>
          )} */}

          {!isAdminRole && (
            <View>
              <Text style={[styles.mt20]}>Courses</Text>
              <View style={[styles.tableContainer, styles.tHead]}>
                <Text style={[styles.tableHeaderCell]}>Learning</Text>
                <Text style={[styles.tableHeaderCell]}>Status</Text>
                <Text style={[styles.tableHeaderCell]}>Due</Text>
                <Text style={[styles.tableHeaderCell]}>Completed</Text>
              </View>
              {courseData?.length
                ? courseData?.map((row: any) => {
                    const data = chartData.find(
                      (item) => item.text === row.status
                    );
                    return (
                      <View
                        style={[styles.tableContainer, styles.tRow]}
                        key={row._id}
                      >
                        <Text style={[styles.tableRowCell]}>
                          {truncateText(row?.course?.name, 20)}
                        </Text>
                        <View style={[styles.tableRowCell, styles.flexStart]}>
                          <View
                            style={[
                              styles.chip,
                              { backgroundColor: data?.color },
                            ]}
                          >
                            <Text>{row.status}</Text>
                          </View>
                        </View>
                        <Text style={styles.tableRowCell}>
                          {row?.dueAt
                            ? moment(row.dueAt).format('DD-MM-YYYY')
                            : '-'}
                        </Text>
                        <Text style={styles.tableRowCell}>
                          {row?.completedAt
                            ? moment(row.completedAt).format('DD-MM-YYYY')
                            : '-'}
                        </Text>
                      </View>
                    );
                  })
                : null}
            </View>
          )}

          {/* {userSummaryData?.length > 0 &&
            <View>
              <Text style={[styles.mt20]}>Summary</Text>
              <View style={[styles.tableContainer, styles.tHead]}>
                <Text style={[styles.tableHeaderCell1]}>Summary Name</Text>
                <Text style={[styles.tableHeaderCell]}>Created At</Text>
                <Text style={[styles.tableHeaderCell]}>Updated At</Text>
              </View>
              {userSummaryData?.length
                ? userSummaryData?.map((row: any) => {
                  return (
                    <View
                      style={[styles.tableContainer, styles.tRow]}
                      key={row._id}
                    >
                      <Text style={[styles.tableRowCell1]}>
                        {truncateText(row?.summary, 20)}
                      </Text>
                      <Text style={styles.tableRowCell}>
                        {row?.createdAt
                          ? moment(row.dueAt).format('DD-MM-YYYY')
                          : '-'}
                      </Text>
                      <Text style={styles.tableRowCell}>
                        {row?.updatedAt
                          ? moment(row.completedAt).format('DD-MM-YYYY')
                          : '-'}
                      </Text>
                    </View>
                  );
                })
                : null}
            </View>
          } */}

          {userSummaryData?.length > 0 && (
            <View>
              <Text style={[styles.mt20]}>Summary</Text>
              <View style={[styles.tableContainer, styles.tHead]}>
                <Text style={[styles.tableHeaderCell1]}>Summary Name</Text>
                <Text style={[styles.tableHeaderCell]}>Created At</Text>
                <Text style={[styles.tableHeaderCell]}>Updated At</Text>
              </View>
              {userSummaryData
                .filter(
                  (row: any) => row?.summary && row?.summary.trim() !== ''
                ) // Filter out entries with undefined or empty summary
                .map((row: any) => (
                  <View
                    style={[styles.tableContainer, styles.tRow]}
                    key={row._id}
                  >
                    <Text style={[styles.tableRowCell1]}>
                      {row.summary ? truncateText(row.summary, 20) : '-'}
                    </Text>
                    <Text style={styles.tableRowCell}>
                      {row.createdAt
                        ? moment(row.createdAt).format('DD-MM-YYYY')
                        : '-'}
                    </Text>
                    <Text style={styles.tableRowCell}>
                      {row.updatedAt
                        ? moment(row.updatedAt).format('DD-MM-YYYY')
                        : '-'}
                    </Text>
                  </View>
                ))}
            </View>
          )}
        </View>
      </Page>
    </Document>
  );
};
