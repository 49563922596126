import React, { useEffect, useState } from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import LensBlurIcon from '@mui/icons-material/LensBlur';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Button, Checkbox, useTheme } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useFieldArray } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import gallery from '../../assets/images/gallery.png';
import pdf from '../../assets/images/pdf.png';
import { useDeleteAssessmentQuestionMutation } from '../../store/apis/assessment';
import { useGetSettingsQuery } from '../../store/apis/setting';
import { getGeneratedOptions, notify } from '../../utils/commonFunctions';
import textConst from '../../utils/textConst';
import AlertDialog from '../AlertDialog';
import HookAutoComplete from '../HookAutoComplete';
import HookCheckboxField from '../HookCheckboxField';
import HookRadioGroupField from '../HookRadioGroupField';
import HookTextArea from '../HookTextArea';
import HookTextField from '../HookTextfield';

interface LearningQuestionsProps {
  control?: any;
  errors?: any;
  setValue?: any;
  getValue?: any;
  watch?: any;
  setIsOrderChanged?: any;
  saveButtonClicked?: boolean | null;
}
export type ItemType = {
  questionText: string;
  option: any;
  choices: ChoiceType[];
  selectedAnswer: any;
  type: string;
  hint: string;
  fileType: string;
  file: string;
};
export type ChoiceType = {
  choiceText: string;
  isRight: boolean;
};

export const questionItem: ItemType = {
  questionText: '',
  hint: '',
  option: { _id: 4, name: '4' },
  choices: Array.from({ length: 4 }, (_, index) => ({
    choiceText: '',
    isRight: !!(index === 0),
  })),
  selectedAnswer: '1',
  type: 'Single',
  file: '',
  fileType: 'question',
};

const LearningQuestions: React.FC<LearningQuestionsProps> = ({
  control,
  errors,
  setValue,
  getValue,
  watch,
  setIsOrderChanged,
  saveButtonClicked,
}) => {
  const theme = useTheme();
  const [options, setOptions] = useState<any>([]);
  const [imageUpload, setImageUpload] = useState<File | null>();
  const [imageUrl, setImageUrl] = useState<any | null>();
  const [imageRadio, setImageRadio] = useState<any | null>();
  const [pdfRadio, setPdfRadio] = useState<any | null>();
  const [pdfUpload, setPdfUpload] = useState<File | null>();
  const [pdfUrl, setPdfUrl] = useState<any | null>();

  const {
    data,
    isError: isSettingError,
    error: settingError,
  } = useGetSettingsQuery();

  useEffect(() => {
    if (isSettingError) {
      notify(settingError?.data?.message, 'error', 1200);
    }
  }, [isSettingError, settingError?.data?.message]);

  useEffect(() => {
    const options = getGeneratedOptions(data?.questionMaxOptionsLimit?.value);
    setOptions(options);
  }, [data]);

  const {
    fields: questions,
    append,
    update,
    remove,
    move,
  }: any = useFieldArray({
    control,
    name: 'questions',
  });
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<any>(null);

  const { id } = useParams();

  // const [expandedIndex, setExpandedIndex] = useState<number | undefined>(
  //   id ? undefined : 0
  // );

  const [expandedIndex, setExpandedIndex] = useState<number[]>(id ? [] : [0]);

  useEffect(() => {
    if (saveButtonClicked && errors.questions) {
      const quesArr = Object.keys(errors.questions);
      const lastItem = quesArr[quesArr.length - 1];
      setExpandedIndex([Number(lastItem)]);
    }
  }, [errors.questions, saveButtonClicked]);

  const handleClickOpen = (value: any) => {
    setOpenDeleteDialog(true);
    setSelectedRecord(value);
  };

  const [
    deleteQuestion,
    {
      isSuccess: isDeleteSuccess,
      isError: isDeleteError,
      error: deleteError,
      isLoading: isDeleteLoading,
    },
  ] = useDeleteAssessmentQuestionMutation();

  useEffect(() => {
    if (isDeleteError) {
      notify(deleteError?.data?.message, 'error');
    }
  }, [deleteError, isDeleteError]);

  useEffect(() => {
    if (isDeleteSuccess) {
      notify('Question deleted successfully', 'success');
    }
  }, [isDeleteSuccess]);

  const handleClose = () => {
    setOpenDeleteDialog(false);
  };

  const handleDelete = async (value: any) => {
    const res = await deleteQuestion({
      assessmentId: id,
      questionId: value?._id,
    });

    if (res?.data) {
      const deletedIndex = questions?.findIndex(
        (item: any) => item._id === selectedRecord?._id
      );
      if (deletedIndex !== -1) {
        remove(deletedIndex);
        setOpenDeleteDialog(false);
      }
    } else {
      setOpenDeleteDialog(false);
    }
  };

  const handleAddingItems = () => {
    const newItemIndex = questions.length;
    append(questionItem);
    setExpandedIndex([newItemIndex]);
  };

  // const handleChange =
  //   (index: number) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
  //     if (isExpanded) {
  //       setExpandedIndex(index);
  //     } else {
  //       setExpandedIndex(-1);
  //     }
  //   };
  const handleChange =
    (index: number) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
      if (isExpanded) {
        setExpandedIndex((prevIndices: any) => [...prevIndices, index]);
      } else {
        setExpandedIndex((prevIndices: any) =>
          prevIndices.filter((i: any) => i !== index)
        );
      }
    };

  const onDragStart = (e: any, index: number) => {
    e.dataTransfer.setData('index', index);
  };

  const onDragOver = (e: any) => {
    e.preventDefault();
  };

  const onDrop = (e: any) => {
    e.preventDefault();
    const sourceIndex = e.dataTransfer.getData('index');
    const targetIndex = e.currentTarget.getAttribute('data-index');

    if (sourceIndex !== targetIndex) {
      move(Number(sourceIndex), Number(targetIndex));
      setIsOrderChanged(true);
    }
  };

  const handleInputImageChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    questionIndex: any
  ) => {
    const file = event.target.files?.[0];

    if (file) {
      const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
      const maxSizeInBytes = 15 * 1024 * 1024; // 15MB

      if (allowedTypes.includes(file.type) && file.size <= maxSizeInBytes) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const imageDataUrl = e.target?.result as string;

          setImageUrl((prevImageUrl: any) => ({
            ...prevImageUrl,
            [`questions[${imageRadio}].file`]: imageDataUrl,
          }));
          setImageUpload(file);
          setValue(`questions[${questions?.length - 1}].file`, file);
        };
        reader.readAsDataURL(file);
      } else {
        if (!allowedTypes.includes(file.type)) {
          alert('Invalid file type. Please select a valid image file.');
        } else {
          alert(
            'File size exceeds the allowed limit (15MB). Please choose a smaller file.'
          );
        }
      }
    }
  };

  const handleInputPdfChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    questionIndex: number
  ) => {
    const file = event.target.files?.[0];

    if (file) {
      const allowedType = 'application/pdf';
      const maxSizeInBytes = 15 * 1024 * 1024; // 15MB

      if (file.type === allowedType && file.size <= maxSizeInBytes) {
        setPdfUpload(file);
        setPdfUrl((prevImageUrl: any) => ({
          ...prevImageUrl,
          [`questions[${pdfRadio}].file`]: file,
        }));
        setValue(`questions[${questions?.length - 1}].file`, file);
      } else {
        if (file.type !== allowedType) {
          alert('Invalid file type. Please select a valid PDF file.');
        } else {
          alert(
            'File size exceeds the allowed limit (15MB). Please choose a smaller file.'
          );
        }
      }
    }
  };

  return (
    <>
      {questions?.map((ele: any, index: number) => {
        const selectedValue = `${
          ele?.choices.findIndex((item: any) => item.isRight) + 1
        }`;
        return (
          <React.Fragment key={ele.id + index}>
            <Accordion
              // expanded={index === expandedIndex}
              expanded={expandedIndex.includes(index)}
              onChange={handleChange(index)}
              sx={{
                'mt': 3,
                'overflow': 'hidden',
                '&.MuiAccordion-root': {
                  borderRadius: 2,
                },
              }}
            >
              <AccordionSummary
                draggable
                onDragStart={(e) => onDragStart(e, index)}
                onDragOver={onDragOver}
                onDrop={onDrop}
                data-index={index}
                expandIcon={
                  <PlayArrowIcon
                    sx={{
                      color: (theme: any) => theme.palette.grey[200],
                      transform: 'rotate(90deg)',
                    }}
                  />
                }
                sx={{
                  'backgroundColor': (theme) => theme.palette.grey[500],
                  'color': (theme) => theme.palette.grey[100],
                  '& .MuiAccordionSummary-content': {
                    width: '100%',
                    overflow: 'hidden',
                    display: 'block',
                  },
                }}
              >
                <Stack direction={'row'} alignItems={'start'} gap={1}>
                  <LensBlurIcon
                    sx={{
                      cursor: 'grab',
                      mt: '4px',
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  />
                  <Typography
                    variant="h6"
                    fontWeight={400}
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      marginRight: '8px',
                    }}
                  >
                    Question {index + 1}{' '}
                    {watch(`questions[${index}].questionText`)?.trim().length
                      ? ': '
                      : ''}
                    {watch(`questions[${index}].questionText`)}
                  </Typography>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <Grid item xs={12}>
                  <Stack
                    sx={{
                      flexDirection: { xs: 'column', md: 'row' },
                      alignItems: { xs: 'flex-start', md: 'center' },
                    }}
                  >
                    <Typography
                      mr={3}
                      sx={{ minWidth: 215 }}
                      color={(theme) => theme.palette.grey[500]}
                    >
                      What do you want to upload?
                    </Typography>
                    <HookRadioGroupField
                      control={control}
                      defaultValue={questions?.[index]?.fileType}
                      row={true}
                      name={`questions?.[${index}].fileType`}
                      onChange={(e: any) => {
                        const currentQuestion = getValue('questions');
                        update(index, {
                          ...currentQuestion[index],
                          fileType: e.target.defaultValue,
                          // file: '',
                        });
                        // setImageUpload(null);
                        // setPdfUpload(null);
                      }}
                    >
                      <FormControlLabel
                        value="question"
                        control={
                          <Radio
                            checked={
                              questions?.[index].fileType === 'question' ||
                              !questions?.[index].fileType
                            }
                            disabled={!!(id && ele?._id)}
                            sx={{
                              '&, &.Mui-checked': {
                                color:
                                  id && ele?._id
                                    ? 'rgba(0, 0, 0, 0.26)'
                                    : 'auto',
                              },
                            }}
                          />
                        }
                        label={
                          <Typography color={theme.palette.grey[500]}>
                            {textConst.questions}
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="image"
                        control={
                          <Radio
                            checked={questions?.[index].fileType === 'image'}
                            sx={{
                              '&, &.Mui-checked': {
                                color:
                                  id && ele?._id
                                    ? 'rgba(0, 0, 0, 0.26)'
                                    : 'auto',
                              },
                            }}
                            disabled={!!(id && ele?._id)}
                          />
                        }
                        label={
                          <Typography color={theme.palette.grey[500]}>
                            {textConst.image}
                          </Typography>
                        }
                        onClick={(e) => setImageRadio(index)}
                      />
                      <FormControlLabel
                        value="pdf"
                        control={
                          <Radio
                            checked={questions?.[index].fileType === 'pdf'}
                            sx={{
                              '&, &.Mui-checked': {
                                color:
                                  id && ele?._id
                                    ? 'rgba(0, 0, 0, 0.26)'
                                    : 'auto',
                              },
                            }}
                            disabled={!!(id && ele?._id)}
                          />
                        }
                        label={
                          <Typography color={theme.palette.grey[500]}>
                            {textConst.pdf}
                          </Typography>
                        }
                        onClick={(e) => setPdfRadio(index)}
                      />
                    </HookRadioGroupField>
                  </Stack>
                </Grid>
                {(ele?.fileType === 'question' || !ele?.fileType) && (
                  <>
                    <Grid container spacing={3} py={2} key={ele.id}>
                      <Grid item xs={12} md={6} xl={4}>
                        <HookTextField
                          disabled={!!(id && ele?._id)}
                          control={control}
                          name={`questions[${index}].questionText`}
                          label="Question"
                          // id={`questionText-${index}`}
                          inputProps={{ autoComplete: 'off' }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} xl={4}>
                        <HookAutoComplete
                          disabled={!!(id && ele?._id)}
                          options={options}
                          multiple={false}
                          // id="option"
                          id={`option-${index}`}
                          label={'Option'}
                          name={`questions[${index}].option`}
                          control={control}
                          getOptionLabel="name"
                          onChangeVal={(e: any) => {
                            let newArray: any = ele.choices;
                            if (ele.choices.length < e?._id) {
                              const extraFields = Array.from(
                                {
                                  length:
                                    Number(e?._id) - Number(ele.choices.length),
                                },
                                () => ({
                                  choiceText: '',
                                  isRight: false,
                                })
                              );
                              newArray = [...newArray, ...extraFields];
                            } else if (ele.choices.length > e?._id) {
                              newArray = newArray.slice(0, Number(e?._id));
                            }
                            const currentQuestion = getValue('questions');
                            update(index, {
                              ...currentQuestion[index],
                              option: e,
                              choices: newArray,
                            });
                          }}
                          inputProps={{ autoComplete: 'off' }}
                        />
                      </Grid>
                      <Grid item xs={12} xl={4}>
                        <Stack
                          alignItems={{
                            xs: 'start',
                            sm: 'center',
                          }}
                          justifyContent="flex-start"
                          flexDirection={{
                            xs: 'column',
                            sm: 'row',
                          }}
                        >
                          <Typography
                            marginRight={'20px'}
                            color={theme.palette.grey[500]}
                          >
                            {textConst.answer}:
                          </Typography>
                          <HookRadioGroupField
                            control={control}
                            defaultValue={questions?.[index]?.type}
                            row={true}
                            name={`questions?.[${index}].type`}
                            onChange={(e: any) => {
                              const currentQuestion = getValue('questions');
                              update(index, {
                                ...currentQuestion[index],
                                type: e.target.defaultValue,
                              });
                            }}
                          >
                            <FormControlLabel
                              value="Single"
                              control={
                                <Radio
                                  checked={questions?.[index].type === 'Single'}
                                  disabled={!!(id && ele?._id)}
                                  sx={{
                                    '&, &.Mui-checked': {
                                      color:
                                        id && ele?._id
                                          ? 'rgba(0, 0, 0, 0.26)'
                                          : 'auto',
                                    },
                                  }}
                                />
                              }
                              label={
                                <Typography color={theme.palette.grey[500]}>
                                  {textConst.single} {textConst.answer}
                                </Typography>
                              }
                            />
                            <FormControlLabel
                              value="Multiple"
                              control={
                                <Radio
                                  checked={
                                    questions?.[index].type === 'Multiple'
                                  }
                                  sx={{
                                    '&, &.Mui-checked': {
                                      color:
                                        id && ele?._id
                                          ? 'rgba(0, 0, 0, 0.26)'
                                          : 'auto',
                                    },
                                  }}
                                  disabled={!!(id && ele?._id)}
                                />
                              }
                              label={
                                <Typography color={theme.palette.grey[500]}>
                                  {textConst.multiple} {textConst.answer}
                                </Typography>
                              }
                            />
                          </HookRadioGroupField>
                        </Stack>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      rowSpacing={ele?.type === 'Single' ? 2 : 2.5}
                      sx={{
                        '& .MuiFormGroup-root': {
                          width: '100%',
                        },
                        'pt': ele?.type === 'Single' ? 2.5 : 0.5,
                        'pb': ele?.type === 'Single' ? 0 : 2.5,
                      }}
                    >
                      {ele?.type === 'Single' ? (
                        <HookRadioGroupField
                          control={control}
                          defaultValue={selectedValue}
                          row={true}
                          name={`questions[${index}].selectedAnswer`}
                          onChange={(e: any) => {
                            ele.choices.forEach(
                              (item: any, choiceIndex: any) => {
                                setValue(
                                  `questions[${index}].choices[${choiceIndex}].isRight`,
                                  choiceIndex + 1 ===
                                    Number(e.target.defaultValue)
                                );
                              }
                            );
                          }}
                          sx={{
                            'width': '100%',
                            '& .MuiFormGroup-root': {
                              width: '100%',
                            },
                          }}
                        >
                          {ele?.choices?.map(
                            (choiceItem: any, choiceIndex: any) => {
                              return (
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  // lg={3}
                                  // xl={3}
                                  key={choiceIndex.toString()}
                                  pb={2.5}
                                >
                                  <FormControlLabel
                                    sx={{
                                      'width': '100%',
                                      '& .MuiTypography-root': {
                                        display: 'flex',
                                        width: '100%',
                                      },
                                    }}
                                    value={`${choiceIndex + 1}`}
                                    control={
                                      <Radio
                                        disabled={!!(id && ele?._id)}
                                        sx={{
                                          '&, &.Mui-checked': {
                                            color:
                                              id && ele?._id
                                                ? 'rgba(0, 0, 0, 0.26)'
                                                : 'auto',
                                          },
                                        }}
                                      />
                                    }
                                    label={
                                      <HookTextArea
                                        rows={2}
                                        disabled={!!(id && ele?._id)}
                                        sx={{
                                          display: 'flex',
                                          flexGrow: 1,
                                          flexDirection: 'column',
                                          width: '100%',
                                        }}
                                        fullWidth
                                        control={control}
                                        name={`questions[${index}].choices[${choiceIndex}].choiceText`}
                                        label="Answer"
                                        customError={true}
                                        error={
                                          !!errors?.questions?.[index]
                                            ?.choices?.[choiceIndex]
                                        }
                                        inputProps={{ autoComplete: 'off' }}
                                      />
                                    }
                                  />
                                </Grid>
                              );
                            }
                          )}
                        </HookRadioGroupField>
                      ) : (
                        <HookCheckboxField
                          control={control}
                          defaultValue="selectedAnswer"
                          name={`questions[${index}].selectedAnswer`}
                        >
                          {ele?.choices?.map(
                            (choiceItem: any, choiceIndex: any) => {
                              return (
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  // lg={3}
                                  // xl={3}
                                  key={choiceIndex.toString()}
                                >
                                  <FormControlLabel
                                    sx={{
                                      'width': '100%',
                                      '& .MuiTypography-root': {
                                        display: 'flex',
                                        width: '100%',
                                      },
                                    }}
                                    value={`${choiceIndex + 1}`}
                                    control={
                                      <Checkbox
                                        disabled={!!(id && ele?._id)}
                                        defaultChecked={choiceItem?.isRight}
                                        onChange={(e) => {
                                          setValue(
                                            `questions[${index}].choices[${choiceIndex}].isRight`,
                                            e.target.checked
                                          );
                                        }}
                                        sx={{
                                          '&, &.Mui-checked': {
                                            color:
                                              id && ele?._id
                                                ? 'rgba(0, 0, 0, 0.26)'
                                                : '#3C4764',
                                          },
                                        }}
                                      />
                                    }
                                    label={
                                      <HookTextArea
                                        rows={2}
                                        disabled={!!(id && ele?._id)}
                                        sx={{
                                          display: 'flex',
                                          flexGrow: 1,
                                          width: '100%',
                                        }}
                                        fullWidth
                                        control={control}
                                        name={`questions[${index}].choices[${choiceIndex}].choiceText`}
                                        label="Answer"
                                        customError={true}
                                        error={
                                          !!errors?.questions?.[index]
                                            ?.choices?.[choiceIndex]
                                        }
                                        inputProps={{ autoComplete: 'off' }}
                                      />
                                    }
                                  />
                                </Grid>
                              );
                            }
                          )}
                        </HookCheckboxField>
                      )}
                    </Grid>
                    <Grid container item xs={12} mr={'10px'} mb={2}>
                      <HookTextArea
                        disabled={!!(id && ele?._id)}
                        // inputProps={{ maxLength: 1000 }}
                        name={`questions[${index}].hint`}
                        label={'Hint'}
                        control={control}
                        rows={2}
                        isRequired={false}
                        inputProps={{ autoComplete: 'off' }}
                      />
                    </Grid>
                  </>
                )}

                {ele?.fileType === 'image' && (
                  <>
                    <Grid container spacing={3} py={2} key={ele.id}>
                      <Grid item xs={12} md={6} xl={3}>
                        <Box
                          sx={{
                            margin: '0px 20px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          {ele?.fileUrl ? (
                            <Box
                              display={'flex'}
                              alignItems={'center'}
                              justifyContent={'center'}
                              flexDirection={'column'}
                              gap={2}
                              width={'100%'}
                              sx={{
                                minHeight: '173.63px',
                                position: 'relative',
                                border: (theme) =>
                                  `2px dashed ${theme.palette.grey[300]}`,
                                borderRadius: 2,
                                background: (theme) =>
                                  `${theme.palette.grey[100]}`,
                              }}
                            >
                              <Box
                                sx={{
                                  borderRadius: 5,
                                  objectFit: 'contain',
                                  width: '100%',
                                  height: {
                                    xs: '100px',
                                    md: '150px',
                                  },
                                }}
                                component={'img'}
                                src={ele?.fileUrl}
                                alt="slide_img"
                              ></Box>
                            </Box>
                          ) : (
                            <>
                              {imageUpload ? (
                                <>
                                  <Box
                                    gap={2}
                                    width={'100%'}
                                    sx={{
                                      minHeight: '173.63px',
                                      position: 'relative',
                                      border: (theme) =>
                                        `2px dashed ${theme.palette.grey[300]}`,
                                      borderRadius: 2,
                                      background: (theme) =>
                                        `${theme.palette.grey[100]}`,
                                    }}
                                  >
                                    {imageUrl[`questions[${index}].file`] ===
                                    '' ? (
                                      <>
                                        <Box
                                          mt={2}
                                          sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                          }}
                                          onClick={() => {
                                            const fileInput =
                                              document.getElementById(
                                                'account-settings-upload-image'
                                              );
                                            if (fileInput) {
                                              fileInput.click();
                                            }
                                          }}
                                        >
                                          <img
                                            src={gallery}
                                            alt="image_file"
                                            width={70}
                                            height={70}
                                          />
                                          <Typography
                                            variant="subtitle1"
                                            textAlign={'center'}
                                            sx={{
                                              color: (theme) =>
                                                theme?.palette.grey[600],
                                            }}
                                          >
                                            Upload Image
                                          </Typography>
                                          <Typography
                                            variant="subtitle1"
                                            textAlign={'center'}
                                            sx={{
                                              px: 1,
                                              color: (theme) =>
                                                theme?.palette.grey[400],
                                            }}
                                          >
                                            <Box>
                                              <Typography>
                                                You Can Upload Only PNG & JPG.
                                              </Typography>
                                            </Box>
                                          </Typography>
                                        </Box>
                                      </>
                                    ) : (
                                      <>
                                        {imageUrl[
                                          `questions[${index}].file`
                                        ] === undefined ? (
                                          <>
                                            <Box
                                              mt={2}
                                              sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                cursor: 'pointer',
                                              }}
                                              onClick={() => {
                                                const fileInput =
                                                  document.getElementById(
                                                    'account-settings-upload-image'
                                                  );
                                                if (fileInput) {
                                                  fileInput.click();
                                                }
                                              }}
                                            >
                                              <img
                                                src={gallery}
                                                alt="image_file"
                                                width={70}
                                                height={70}
                                              />
                                              <Typography
                                                variant="subtitle1"
                                                textAlign={'center'}
                                                sx={{
                                                  color: (theme) =>
                                                    theme?.palette.grey[600],
                                                }}
                                              >
                                                Upload Image
                                              </Typography>
                                              <Typography
                                                variant="subtitle1"
                                                textAlign={'center'}
                                                sx={{
                                                  px: 1,
                                                  color: (theme) =>
                                                    theme?.palette.grey[400],
                                                }}
                                              >
                                                <Box>
                                                  <Typography>
                                                    You Can Upload Only PNG &
                                                    JPG.
                                                  </Typography>
                                                </Box>
                                              </Typography>
                                            </Box>
                                          </>
                                        ) : (
                                          <>
                                            <Box
                                              sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                              }}
                                            >
                                              <CancelIcon
                                                sx={{
                                                  color: 'red',
                                                  cursor: 'pointer',
                                                }}
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  setImageUrl(
                                                    (prevImageUrl: any) => ({
                                                      ...prevImageUrl,
                                                      [`questions[${index}].file`]:
                                                        '',
                                                    })
                                                  );
                                                  setValue(
                                                    `questions[${index}].file`,
                                                    ''
                                                  );
                                                  setImageRadio(index);
                                                }}
                                              />
                                            </Box>
                                            <Box
                                              sx={{
                                                borderRadius: 5,
                                                objectFit: 'contain',
                                                width: '100%',
                                                height: {
                                                  xs: '100px',
                                                  md: '150px',
                                                },
                                              }}
                                              // onClick={() => {
                                              //   const fileInput = document.getElementById('account-settings-upload-image');
                                              //   if (fileInput) {
                                              //     fileInput.click();
                                              //   }
                                              // }}
                                              component={'img'}
                                              src={
                                                imageUrl &&
                                                imageUrl[
                                                  `questions[${index}].file`
                                                ]
                                              }
                                              alt="slide_img"
                                            ></Box>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </Box>
                                </>
                              ) : (
                                <>
                                  <Box
                                    gap={2}
                                    width={'100%'}
                                    sx={{
                                      minHeight: '173.63px',
                                      cursor: 'pointer',
                                      position: 'relative',
                                      border: (theme) =>
                                        `2px dashed ${theme.palette.grey[300]}`,
                                      borderRadius: 2,
                                      background: (theme) =>
                                        `${theme.palette.grey[100]}`,
                                    }}
                                    onClick={() => {
                                      const fileInput = document.getElementById(
                                        'account-settings-upload-image'
                                      );
                                      if (fileInput) {
                                        fileInput.click();
                                      }
                                    }}
                                  >
                                    <Box
                                      mt={2}
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <img
                                        src={gallery}
                                        alt="image_file"
                                        width={70}
                                        height={70}
                                      />
                                      <Typography
                                        variant="subtitle1"
                                        textAlign={'center'}
                                        sx={{
                                          color: (theme) =>
                                            theme?.palette.grey[600],
                                        }}
                                      >
                                        Upload Image
                                      </Typography>
                                      <Typography
                                        variant="subtitle1"
                                        textAlign={'center'}
                                        sx={{
                                          px: 1,
                                          color: (theme) =>
                                            theme?.palette.grey[400],
                                        }}
                                      >
                                        <Box>
                                          <Typography>
                                            You Can Upload Only PNG & JPG.
                                          </Typography>
                                        </Box>
                                      </Typography>
                                    </Box>
                                  </Box>
                                </>
                              )}
                            </>
                          )}
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6} xl={4}>
                        <HookTextField
                          disabled={!!(id && ele?._id)}
                          control={control}
                          name={`questions[${index}].questionText`}
                          label="Question"
                          // id={`questionText-${index}`}
                          inputProps={{ autoComplete: 'off' }}
                        />

                        <HookAutoComplete
                          sx={{ mt: 4 }}
                          disabled={!!(id && ele?._id)}
                          options={options}
                          multiple={false}
                          // id="option"
                          id={`option-${index}`}
                          label={'Option'}
                          name={`questions[${index}].option`}
                          control={control}
                          getOptionLabel="name"
                          onChangeVal={(e: any) => {
                            let newArray: any = ele.choices;
                            if (ele.choices.length < e?._id) {
                              const extraFields = Array.from(
                                {
                                  length:
                                    Number(e?._id) - Number(ele.choices.length),
                                },
                                () => ({
                                  choiceText: '',
                                  isRight: false,
                                })
                              );
                              newArray = [...newArray, ...extraFields];
                            } else if (ele.choices.length > e?._id) {
                              newArray = newArray.slice(0, Number(e?._id));
                            }
                            const currentQuestion = getValue('questions');
                            update(index, {
                              ...currentQuestion[index],
                              option: e,
                              choices: newArray,
                            });
                          }}
                          inputProps={{ autoComplete: 'off' }}
                        />
                      </Grid>
                      <Grid item xs={12} xl={4}>
                        <Stack
                          alignItems={{
                            xs: 'start',
                            sm: 'center',
                          }}
                          justifyContent="flex-start"
                          flexDirection={{
                            xs: 'column',
                            sm: 'row',
                          }}
                        >
                          <Typography
                            marginRight={'20px'}
                            color={theme.palette.grey[500]}
                          >
                            {textConst.answer}:
                          </Typography>
                          <HookRadioGroupField
                            control={control}
                            defaultValue={questions?.[index]?.type}
                            row={true}
                            name={`questions?.[${index}].type`}
                            onChange={(e: any) => {
                              const currentQuestion = getValue('questions');
                              update(index, {
                                ...currentQuestion[index],
                                type: e.target.defaultValue,
                              });
                            }}
                          >
                            <FormControlLabel
                              value="Single"
                              control={
                                <Radio
                                  checked={questions?.[index].type === 'Single'}
                                  disabled={!!(id && ele?._id)}
                                  sx={{
                                    '&, &.Mui-checked': {
                                      color:
                                        id && ele?._id
                                          ? 'rgba(0, 0, 0, 0.26)'
                                          : 'auto',
                                    },
                                  }}
                                />
                              }
                              label={
                                <Typography color={theme.palette.grey[500]}>
                                  {textConst.single} {textConst.answer}
                                </Typography>
                              }
                            />
                            <FormControlLabel
                              value="Multiple"
                              control={
                                <Radio
                                  checked={
                                    questions?.[index].type === 'Multiple'
                                  }
                                  sx={{
                                    '&, &.Mui-checked': {
                                      color:
                                        id && ele?._id
                                          ? 'rgba(0, 0, 0, 0.26)'
                                          : 'auto',
                                    },
                                  }}
                                  disabled={!!(id && ele?._id)}
                                />
                              }
                              label={
                                <Typography color={theme.palette.grey[500]}>
                                  {textConst.multiple} {textConst.answer}
                                </Typography>
                              }
                            />
                          </HookRadioGroupField>
                        </Stack>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      rowSpacing={ele?.type === 'Single' ? 2 : 2.5}
                      sx={{
                        '& .MuiFormGroup-root': {
                          width: '100%',
                        },
                        'pt': ele?.type === 'Single' ? 2.5 : 0.5,
                        'pb': ele?.type === 'Single' ? 0 : 2.5,
                      }}
                    >
                      {ele?.type === 'Single' ? (
                        <HookRadioGroupField
                          control={control}
                          defaultValue={selectedValue}
                          row={true}
                          name={`questions[${index}].selectedAnswer`}
                          onChange={(e: any) => {
                            ele.choices.forEach(
                              (item: any, choiceIndex: any) => {
                                setValue(
                                  `questions[${index}].choices[${choiceIndex}].isRight`,
                                  choiceIndex + 1 ===
                                    Number(e.target.defaultValue)
                                );
                              }
                            );
                          }}
                          sx={{
                            'width': '100%',
                            '& .MuiFormGroup-root': {
                              width: '100%',
                            },
                          }}
                        >
                          {ele?.choices?.map(
                            (choiceItem: any, choiceIndex: any) => {
                              return (
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  // lg={3}
                                  // xl={3}
                                  key={choiceIndex.toString()}
                                  pb={2.5}
                                >
                                  <FormControlLabel
                                    sx={{
                                      'width': '100%',
                                      '& .MuiTypography-root': {
                                        display: 'flex',
                                        width: '100%',
                                      },
                                    }}
                                    value={`${choiceIndex + 1}`}
                                    control={
                                      <Radio
                                        disabled={!!(id && ele?._id)}
                                        sx={{
                                          '&, &.Mui-checked': {
                                            color:
                                              id && ele?._id
                                                ? 'rgba(0, 0, 0, 0.26)'
                                                : 'auto',
                                          },
                                        }}
                                      />
                                    }
                                    label={
                                      <HookTextArea
                                        rows={2}
                                        disabled={!!(id && ele?._id)}
                                        sx={{
                                          display: 'flex',
                                          flexGrow: 1,
                                          flexDirection: 'column',
                                          width: '100%',
                                        }}
                                        fullWidth
                                        control={control}
                                        name={`questions[${index}].choices[${choiceIndex}].choiceText`}
                                        label="Answer"
                                        customError={true}
                                        error={
                                          !!errors?.questions?.[index]
                                            ?.choices?.[choiceIndex]
                                        }
                                        inputProps={{ autoComplete: 'off' }}
                                      />
                                    }
                                  />
                                </Grid>
                              );
                            }
                          )}
                        </HookRadioGroupField>
                      ) : (
                        <HookCheckboxField
                          control={control}
                          defaultValue="selectedAnswer"
                          name={`questions[${index}].selectedAnswer`}
                        >
                          {ele?.choices?.map(
                            (choiceItem: any, choiceIndex: any) => {
                              return (
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  // lg={3}
                                  // xl={3}
                                  key={choiceIndex.toString()}
                                >
                                  <FormControlLabel
                                    sx={{
                                      'width': '100%',
                                      '& .MuiTypography-root': {
                                        display: 'flex',
                                        width: '100%',
                                      },
                                    }}
                                    value={`${choiceIndex + 1}`}
                                    control={
                                      <Checkbox
                                        disabled={!!(id && ele?._id)}
                                        defaultChecked={choiceItem?.isRight}
                                        onChange={(e) => {
                                          setValue(
                                            `questions[${index}].choices[${choiceIndex}].isRight`,
                                            e.target.checked
                                          );
                                        }}
                                        sx={{
                                          '&, &.Mui-checked': {
                                            color:
                                              id && ele?._id
                                                ? 'rgba(0, 0, 0, 0.26)'
                                                : '#3C4764',
                                          },
                                        }}
                                      />
                                    }
                                    label={
                                      <HookTextArea
                                        rows={2}
                                        disabled={!!(id && ele?._id)}
                                        sx={{
                                          display: 'flex',
                                          flexGrow: 1,
                                          width: '100%',
                                        }}
                                        fullWidth
                                        control={control}
                                        name={`questions[${index}].choices[${choiceIndex}].choiceText`}
                                        label="Answer"
                                        customError={true}
                                        error={
                                          !!errors?.questions?.[index]
                                            ?.choices?.[choiceIndex]
                                        }
                                        inputProps={{ autoComplete: 'off' }}
                                      />
                                    }
                                  />
                                </Grid>
                              );
                            }
                          )}
                        </HookCheckboxField>
                      )}
                    </Grid>
                    <Grid container item xs={12} mr={'10px'} mb={2}>
                      <HookTextArea
                        disabled={!!(id && ele?._id)}
                        // inputProps={{ maxLength: 1000 }}
                        name={`questions[${index}].hint`}
                        label={'Hint'}
                        control={control}
                        rows={2}
                        isRequired={false}
                        inputProps={{ autoComplete: 'off' }}
                      />
                    </Grid>

                    <input
                      hidden
                      type="file"
                      accept="image/png, image/jpeg, image/jpg"
                      onChange={(e) => handleInputImageChange(e, index)}
                      id="account-settings-upload-image"
                    />
                  </>
                )}

                {ele?.fileType === 'pdf' && (
                  <>
                    <Grid container spacing={3} py={2} key={ele.id}>
                      <Grid item xs={12} md={6} xl={3}>
                        <Box
                          sx={{
                            margin: '0px 20px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          {/* {ele?.fileUrl ? (
                            <Box
                              gap={2}
                              width={'100%'}
                              sx={{
                                minHeight: '173.63px',
                                position: 'relative',
                                border: (theme) => `2px dashed ${theme.palette.grey[300]}`,
                                borderRadius: 2,
                                background: (theme) => `${theme.palette.grey[100]}`,
                              }}
                            >
                              <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                <VisibilityIcon
                                  sx={{
                                    color: (theme) => theme.palette.secondary.dark,
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    window.open(ele.fileUrl, '_blank');
                                  }}
                                />
                              </Box>

                              <Box
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                flexDirection={'column'}
                              >
                                <img
                                  src={pdf}
                                  alt="pdf_file"
                                  width={70}
                                  height={70}
                                />
                                {ele.fileUrl.substring(ele.fileUrl.lastIndexOf('/') + 1)}
                              </Box>
                            </Box>
                          ) :
                            <>
                              {pdfUpload ? (
                                <>
                                  <Box
                                    gap={2}
                                    width={'100%'}
                                    sx={{
                                      minHeight: '173.63px',
                                      cursor: 'pointer',
                                      position: 'relative',
                                      border: (theme) =>
                                        `2px dashed ${theme.palette.grey[300]}`,
                                      borderRadius: 2,
                                      background: (theme) =>
                                        `${theme.palette.grey[100]}`,
                                    }}

                                  >
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                      <VisibilityIcon sx={{
                                        color: (theme) =>
                                          theme.palette.secondary.dark,
                                      }}
                                        onClick={() => {
                                          if (pdfUpload) {
                                            window.open(URL.createObjectURL(pdfUpload), '_blank');
                                          }
                                        }} />
                                      <CancelIcon sx={{ color: 'red' }} onClick={() => {
                                        setPdfUpload(null)
                                      }} />
                                    </Box>
                                    <Box
                                      display={'flex'}
                                      alignItems={'center'}
                                      justifyContent={'center'}
                                      flexDirection={'column'}
                                      gap={2}
                                      mt={2}
                                      onClick={() => {
                                        const fileInput = document.getElementById('account-settings-upload-pdf');
                                        if (fileInput) {
                                          fileInput.click();
                                        }
                                      }}
                                    >
                                      <img
                                        src={pdf}
                                        alt="pdf_file"
                                        width={70}
                                        height={70}
                                      />
                                      <Box>
                                        {pdfUpload ? (<>{ele?.file ? (<>{ele?.file?.name}</>) : (<>{pdfUpload?.name}</>)}</>)
                                          : (
                                            <>
                                              <Typography
                                                variant="subtitle1"
                                                textAlign={'center'}
                                                sx={{
                                                  color: (theme) => theme?.palette.grey[600],
                                                }}
                                              >
                                                Upload PDF
                                              </Typography>
                                              <Typography
                                                variant="subtitle1"
                                                textAlign={'center'}
                                                sx={{
                                                  px: 1,
                                                  color: (theme) => theme?.palette.grey[400],
                                                }}
                                              >
                                                <Box>
                                                  <Typography>
                                                    You Can Upload Only PDF.
                                                  </Typography>
                                                </Box>
                                              </Typography>
                                            </>)
                                        }
                                      </Box>
                                    </Box>
                                  </Box>
                                </>
                              )
                                : (
                                  <>
                                    <Box
                                      gap={2}
                                      width={'100%'}
                                      sx={{
                                        minHeight: '173.63px',
                                        cursor: 'pointer',
                                        position: 'relative',
                                        border: (theme) =>
                                          `2px dashed ${theme.palette.grey[300]}`,
                                        borderRadius: 2,
                                        background: (theme) =>
                                          `${theme.palette.grey[100]}`,
                                      }}

                                    >
                                      <Box
                                        display={'flex'}
                                        alignItems={'center'}
                                        justifyContent={'center'}
                                        flexDirection={'column'}
                                        gap={2}
                                        mt={2}
                                        onClick={() => {
                                          const fileInput = document.getElementById('account-settings-upload-pdf');
                                          if (fileInput) {
                                            fileInput.click();
                                          }
                                        }}
                                      >
                                        <img
                                          src={pdf}
                                          alt="pdf_file"
                                          width={70}
                                          height={70}
                                        />
                                        <Box>
                                          <Typography
                                            variant="subtitle1"
                                            textAlign={'center'}
                                            sx={{
                                              color: (theme) => theme?.palette.grey[600],
                                            }}
                                          >
                                            Upload PDF
                                          </Typography>
                                          <Typography
                                            variant="subtitle1"
                                            textAlign={'center'}
                                            sx={{
                                              px: 1,
                                              color: (theme) => theme?.palette.grey[400],
                                            }}
                                          >
                                            <Box>
                                              <Typography>
                                                You Can Upload Only PDF.
                                              </Typography>
                                            </Box>
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </>
                                )
                              }
                            </>
                          } */}

                          {ele?.fileUrl ? (
                            <Box
                              gap={2}
                              width={'100%'}
                              sx={{
                                minHeight: '173.63px',
                                position: 'relative',
                                border: (theme) =>
                                  `2px dashed ${theme.palette.grey[300]}`,
                                borderRadius: 2,
                                background: (theme) =>
                                  `${theme.palette.grey[100]}`,
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                }}
                              >
                                <VisibilityIcon
                                  sx={{
                                    color: (theme) =>
                                      theme.palette.secondary.dark,
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    window.open(ele.fileUrl, '_blank');
                                  }}
                                />
                              </Box>

                              <Box
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                flexDirection={'column'}
                              >
                                <img
                                  src={pdf}
                                  alt="pdf_file"
                                  width={70}
                                  height={70}
                                />
                                {ele.fileUrl.substring(
                                  ele.fileUrl.lastIndexOf('/') + 1
                                )}
                              </Box>
                            </Box>
                          ) : (
                            <>
                              {pdfUpload ? (
                                <>
                                  <Box
                                    gap={2}
                                    width={'100%'}
                                    sx={{
                                      minHeight: '173.63px',
                                      position: 'relative',
                                      border: (theme) =>
                                        `2px dashed ${theme.palette.grey[300]}`,
                                      borderRadius: 2,
                                      background: (theme) =>
                                        `${theme.palette.grey[100]}`,
                                    }}
                                  >
                                    {pdfUrl[`questions[${index}].file`] ===
                                    '' ? (
                                      <>
                                        <Box
                                          mt={2}
                                          sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                          }}
                                          onClick={() => {
                                            const fileInput =
                                              document.getElementById(
                                                'account-settings-upload-pdf'
                                              );
                                            if (fileInput) {
                                              fileInput.click();
                                            }
                                          }}
                                        >
                                          <img
                                            src={pdf}
                                            alt="pdf_file"
                                            width={70}
                                            height={70}
                                          />
                                          <Typography
                                            variant="subtitle1"
                                            textAlign={'center'}
                                            sx={{
                                              color: (theme) =>
                                                theme?.palette.grey[600],
                                            }}
                                          >
                                            Upload PDF
                                          </Typography>
                                          <Typography
                                            variant="subtitle1"
                                            textAlign={'center'}
                                            sx={{
                                              px: 1,
                                              color: (theme) =>
                                                theme?.palette.grey[400],
                                            }}
                                          >
                                            <Box>
                                              <Typography>
                                                You Can Upload Only PDF.
                                              </Typography>
                                            </Box>
                                          </Typography>
                                        </Box>
                                      </>
                                    ) : (
                                      <>
                                        {pdfUrl[`questions[${index}].file`] ===
                                        undefined ? (
                                          <>
                                            <Box
                                              mt={2}
                                              sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                cursor: 'pointer',
                                              }}
                                              onClick={() => {
                                                const fileInput =
                                                  document.getElementById(
                                                    'account-settings-upload-pdf'
                                                  );
                                                if (fileInput) {
                                                  fileInput.click();
                                                }
                                              }}
                                            >
                                              <img
                                                src={pdf}
                                                alt="pdf_file"
                                                width={70}
                                                height={70}
                                              />
                                              <Typography
                                                variant="subtitle1"
                                                textAlign={'center'}
                                                sx={{
                                                  color: (theme) =>
                                                    theme?.palette.grey[600],
                                                }}
                                              >
                                                Upload PDF
                                              </Typography>
                                              <Typography
                                                variant="subtitle1"
                                                textAlign={'center'}
                                                sx={{
                                                  px: 1,
                                                  color: (theme) =>
                                                    theme?.palette.grey[400],
                                                }}
                                              >
                                                <Box>
                                                  <Typography>
                                                    You Can Upload Only PDF.
                                                  </Typography>
                                                </Box>
                                              </Typography>
                                            </Box>
                                          </>
                                        ) : (
                                          <>
                                            <Box
                                              sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                              }}
                                            >
                                              <VisibilityIcon
                                                sx={{
                                                  color: (theme) =>
                                                    theme.palette.secondary
                                                      .dark,
                                                  cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                  if (pdfUpload) {
                                                    window.open(
                                                      URL.createObjectURL(
                                                        pdfUpload
                                                      ),
                                                      '_blank'
                                                    );
                                                  }
                                                }}
                                              />
                                              {/* <CancelIcon sx={{ color: 'red', cursor: 'pointer' }} onClick={() => {
                                            setPdfUpload(null)
                                          }} /> */}
                                              <CancelIcon
                                                sx={{
                                                  color: 'red',
                                                  cursor: 'pointer',
                                                }}
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  setPdfUrl(
                                                    (prevImageUrl: any) => ({
                                                      ...prevImageUrl,
                                                      [`questions[${index}].file`]:
                                                        '',
                                                    })
                                                  );
                                                  setValue(
                                                    `questions[${index}].file`,
                                                    ''
                                                  );
                                                  setPdfRadio(index);
                                                }}
                                              />
                                            </Box>
                                            <Box
                                              display={'flex'}
                                              alignItems={'center'}
                                              justifyContent={'center'}
                                              flexDirection={'column'}
                                              sx={{
                                                borderRadius: 5,
                                                objectFit: 'contain',
                                                width: '100%',
                                                height: {
                                                  xs: '100px',
                                                  md: '150px',
                                                },
                                              }}
                                            >
                                              <>
                                                {pdfUrl &&
                                                  pdfUrl[
                                                    `questions[${index}].file`
                                                  ] && (
                                                    <>
                                                      {
                                                        pdfUrl[
                                                          `questions[${index}].file`
                                                        ].name
                                                      }
                                                    </>
                                                  )}
                                              </>
                                            </Box>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </Box>
                                </>
                              ) : (
                                <>
                                  <Box
                                    gap={2}
                                    width={'100%'}
                                    sx={{
                                      minHeight: '173.63px',
                                      cursor: 'pointer',
                                      position: 'relative',
                                      border: (theme) =>
                                        `2px dashed ${theme.palette.grey[300]}`,
                                      borderRadius: 2,
                                      background: (theme) =>
                                        `${theme.palette.grey[100]}`,
                                    }}
                                    onClick={() => {
                                      const fileInput = document.getElementById(
                                        'account-settings-upload-pdf'
                                      );
                                      if (fileInput) {
                                        fileInput.click();
                                      }
                                    }}
                                  >
                                    <Box
                                      mt={2}
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <img
                                        src={pdf}
                                        alt="pdf_file"
                                        width={70}
                                        height={70}
                                      />
                                      <Typography
                                        variant="subtitle1"
                                        textAlign={'center'}
                                        sx={{
                                          color: (theme) =>
                                            theme?.palette.grey[600],
                                        }}
                                      >
                                        Upload PDF
                                      </Typography>
                                      <Typography
                                        variant="subtitle1"
                                        textAlign={'center'}
                                        sx={{
                                          px: 1,
                                          color: (theme) =>
                                            theme?.palette.grey[400],
                                        }}
                                      >
                                        <Box>
                                          <Typography>
                                            You Can Upload Only PDF.
                                          </Typography>
                                        </Box>
                                      </Typography>
                                    </Box>
                                  </Box>
                                </>
                              )}
                            </>
                          )}
                        </Box>
                        <input
                          hidden
                          type="file"
                          accept="application/pdf"
                          onChange={(e) => handleInputPdfChange(e, index)}
                          id="account-settings-upload-pdf"
                        />
                      </Grid>
                      <Grid item xs={12} md={6} xl={4}>
                        <HookTextField
                          disabled={!!(id && ele?._id)}
                          control={control}
                          name={`questions[${index}].questionText`}
                          label="Question"
                          // id={`questionText-${index}`}
                          inputProps={{ autoComplete: 'off' }}
                        />

                        <HookAutoComplete
                          sx={{ mt: 4 }}
                          disabled={!!(id && ele?._id)}
                          options={options}
                          multiple={false}
                          // id="option"
                          id={`questionText-${index}`}
                          label={'Option'}
                          name={`questions[${index}].option`}
                          control={control}
                          getOptionLabel="name"
                          onChangeVal={(e: any) => {
                            let newArray: any = ele.choices;
                            if (ele.choices.length < e?._id) {
                              const extraFields = Array.from(
                                {
                                  length:
                                    Number(e?._id) - Number(ele.choices.length),
                                },
                                () => ({
                                  choiceText: '',
                                  isRight: false,
                                })
                              );
                              newArray = [...newArray, ...extraFields];
                            } else if (ele.choices.length > e?._id) {
                              newArray = newArray.slice(0, Number(e?._id));
                            }
                            const currentQuestion = getValue('questions');
                            update(index, {
                              ...currentQuestion[index],
                              option: e,
                              choices: newArray,
                            });
                          }}
                          inputProps={{ autoComplete: 'off' }}
                        />
                      </Grid>
                      <Grid item xs={12} xl={4}>
                        <Stack
                          alignItems={{
                            xs: 'start',
                            sm: 'center',
                          }}
                          justifyContent="flex-start"
                          flexDirection={{
                            xs: 'column',
                            sm: 'row',
                          }}
                        >
                          <Typography
                            marginRight={'20px'}
                            color={theme.palette.grey[500]}
                          >
                            {textConst.answer}:
                          </Typography>
                          <HookRadioGroupField
                            control={control}
                            defaultValue={questions?.[index]?.type}
                            row={true}
                            name={`questions?.[${index}].type`}
                            onChange={(e: any) => {
                              const currentQuestion = getValue('questions');
                              update(index, {
                                ...currentQuestion[index],
                                type: e.target.defaultValue,
                              });
                            }}
                          >
                            <FormControlLabel
                              value="Single"
                              control={
                                <Radio
                                  checked={questions?.[index].type === 'Single'}
                                  disabled={!!(id && ele?._id)}
                                  sx={{
                                    '&, &.Mui-checked': {
                                      color:
                                        id && ele?._id
                                          ? 'rgba(0, 0, 0, 0.26)'
                                          : 'auto',
                                    },
                                  }}
                                />
                              }
                              label={
                                <Typography color={theme.palette.grey[500]}>
                                  {textConst.single} {textConst.answer}
                                </Typography>
                              }
                            />
                            <FormControlLabel
                              value="Multiple"
                              control={
                                <Radio
                                  checked={
                                    questions?.[index].type === 'Multiple'
                                  }
                                  sx={{
                                    '&, &.Mui-checked': {
                                      color:
                                        id && ele?._id
                                          ? 'rgba(0, 0, 0, 0.26)'
                                          : 'auto',
                                    },
                                  }}
                                  disabled={!!(id && ele?._id)}
                                />
                              }
                              label={
                                <Typography color={theme.palette.grey[500]}>
                                  {textConst.multiple} {textConst.answer}
                                </Typography>
                              }
                            />
                          </HookRadioGroupField>
                        </Stack>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      rowSpacing={ele?.type === 'Single' ? 2 : 2.5}
                      sx={{
                        '& .MuiFormGroup-root': {
                          width: '100%',
                        },
                        'pt': ele?.type === 'Single' ? 2.5 : 0.5,
                        'pb': ele?.type === 'Single' ? 0 : 2.5,
                      }}
                    >
                      {ele?.type === 'Single' ? (
                        <HookRadioGroupField
                          control={control}
                          defaultValue={selectedValue}
                          row={true}
                          name={`questions[${index}].selectedAnswer`}
                          onChange={(e: any) => {
                            ele.choices.forEach(
                              (item: any, choiceIndex: any) => {
                                setValue(
                                  `questions[${index}].choices[${choiceIndex}].isRight`,
                                  choiceIndex + 1 ===
                                    Number(e.target.defaultValue)
                                );
                              }
                            );
                          }}
                          sx={{
                            'width': '100%',
                            '& .MuiFormGroup-root': {
                              width: '100%',
                            },
                          }}
                        >
                          {ele?.choices?.map(
                            (choiceItem: any, choiceIndex: any) => {
                              return (
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  // lg={3}
                                  // xl={3}
                                  key={choiceIndex.toString()}
                                  pb={2.5}
                                >
                                  <FormControlLabel
                                    sx={{
                                      'width': '100%',
                                      '& .MuiTypography-root': {
                                        display: 'flex',
                                        width: '100%',
                                      },
                                    }}
                                    value={`${choiceIndex + 1}`}
                                    control={
                                      <Radio
                                        disabled={!!(id && ele?._id)}
                                        sx={{
                                          '&, &.Mui-checked': {
                                            color:
                                              id && ele?._id
                                                ? 'rgba(0, 0, 0, 0.26)'
                                                : 'auto',
                                          },
                                        }}
                                      />
                                    }
                                    label={
                                      <HookTextArea
                                        rows={2}
                                        disabled={!!(id && ele?._id)}
                                        sx={{
                                          display: 'flex',
                                          flexGrow: 1,
                                          flexDirection: 'column',
                                          width: '100%',
                                        }}
                                        fullWidth
                                        control={control}
                                        name={`questions[${index}].choices[${choiceIndex}].choiceText`}
                                        label="Answer"
                                        customError={true}
                                        error={
                                          !!errors?.questions?.[index]
                                            ?.choices?.[choiceIndex]
                                        }
                                        inputProps={{ autoComplete: 'off' }}
                                      />
                                    }
                                  />
                                </Grid>
                              );
                            }
                          )}
                        </HookRadioGroupField>
                      ) : (
                        <HookCheckboxField
                          control={control}
                          defaultValue="selectedAnswer"
                          name={`questions[${index}].selectedAnswer`}
                        >
                          {ele?.choices?.map(
                            (choiceItem: any, choiceIndex: any) => {
                              return (
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  // lg={3}
                                  // xl={3}
                                  key={choiceIndex.toString()}
                                >
                                  <FormControlLabel
                                    sx={{
                                      'width': '100%',
                                      '& .MuiTypography-root': {
                                        display: 'flex',
                                        width: '100%',
                                      },
                                    }}
                                    value={`${choiceIndex + 1}`}
                                    control={
                                      <Checkbox
                                        disabled={!!(id && ele?._id)}
                                        defaultChecked={choiceItem?.isRight}
                                        onChange={(e) => {
                                          setValue(
                                            `questions[${index}].choices[${choiceIndex}].isRight`,
                                            e.target.checked
                                          );
                                        }}
                                        sx={{
                                          '&, &.Mui-checked': {
                                            color:
                                              id && ele?._id
                                                ? 'rgba(0, 0, 0, 0.26)'
                                                : '#3C4764',
                                          },
                                        }}
                                      />
                                    }
                                    label={
                                      <HookTextArea
                                        rows={2}
                                        disabled={!!(id && ele?._id)}
                                        sx={{
                                          display: 'flex',
                                          flexGrow: 1,
                                          width: '100%',
                                        }}
                                        fullWidth
                                        control={control}
                                        name={`questions[${index}].choices[${choiceIndex}].choiceText`}
                                        label="Answer"
                                        customError={true}
                                        error={
                                          !!errors?.questions?.[index]
                                            ?.choices?.[choiceIndex]
                                        }
                                        inputProps={{ autoComplete: 'off' }}
                                      />
                                    }
                                  />
                                </Grid>
                              );
                            }
                          )}
                        </HookCheckboxField>
                      )}
                    </Grid>
                    <Grid container item xs={12} mr={'10px'} mb={2}>
                      <HookTextArea
                        disabled={!!(id && ele?._id)}
                        // inputProps={{ maxLength: 1000 }}
                        name={`questions[${index}].hint`}
                        label={'Hint'}
                        control={control}
                        rows={2}
                        isRequired={false}
                        inputProps={{ autoComplete: 'off' }}
                      />
                    </Grid>
                  </>
                )}

                {/* <Grid container spacing={3} py={2} key={ele.id}>
                  <Grid item xs={12} md={6} xl={4}>
                    <HookTextField
                      disabled={!!(id && ele?._id)}
                      control={control}
                      name={`questions[${index}].questionText`}
                      label="Question"
                    />
                  </Grid>
                  <Grid item xs={12} md={6} xl={4}>
                    <HookAutoComplete
                      disabled={!!(id && ele?._id)}
                      options={options}
                      multiple={false}
                      id="option"
                      label={'Option'}
                      name={`questions[${index}].option`}
                      control={control}
                      getOptionLabel="name"
                      onChangeVal={(e: any) => {
                        let newArray: any = ele.choices;
                        if (ele.choices.length < e?._id) {
                          const extraFields = Array.from(
                            {
                              length:
                                Number(e?._id) - Number(ele.choices.length),
                            },
                            () => ({
                              choiceText: '',
                              isRight: false,
                            })
                          );
                          newArray = [...newArray, ...extraFields];
                        } else if (ele.choices.length > e?._id) {
                          newArray = newArray.slice(0, Number(e?._id));
                        }
                        const currentQuestion = getValue('questions');
                        update(index, {
                          ...currentQuestion[index],
                          option: e,
                          choices: newArray,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} xl={4}>
                    <Stack
                      alignItems={{
                        xs: 'start',
                        sm: 'center',
                      }}
                      justifyContent="flex-start"
                      flexDirection={{
                        xs: 'column',
                        sm: 'row',
                      }}
                    >
                      <Typography
                        marginRight={'20px'}
                        color={theme.palette.grey[500]}
                      >
                        {textConst.answer}:
                      </Typography>
                      <HookRadioGroupField
                        control={control}
                        defaultValue={questions?.[index]?.type}
                        row={true}
                        name={`questions?.[${index}].type`}
                        onChange={(e: any) => {
                          const currentQuestion = getValue('questions');
                          update(index, {
                            ...currentQuestion[index],
                            type: e.target.defaultValue,
                          });
                        }}
                      >
                        <FormControlLabel
                          value="Single"
                          control={
                            <Radio
                              checked={questions?.[index].type === 'Single'}
                              disabled={!!(id && ele?._id)}
                              sx={{
                                '&, &.Mui-checked': {
                                  color:
                                    id && ele?._id
                                      ? 'rgba(0, 0, 0, 0.26)'
                                      : 'auto',
                                },
                              }}
                            />
                          }
                          label={
                            <Typography color={theme.palette.grey[500]}>
                              {textConst.single} {textConst.answer}
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value="Multiple"
                          control={
                            <Radio
                              checked={questions?.[index].type === 'Multiple'}
                              sx={{
                                '&, &.Mui-checked': {
                                  color:
                                    id && ele?._id
                                      ? 'rgba(0, 0, 0, 0.26)'
                                      : 'auto',
                                },
                              }}
                              disabled={!!(id && ele?._id)}
                            />
                          }
                          label={
                            <Typography color={theme.palette.grey[500]}>
                              {textConst.multiple} {textConst.answer}
                            </Typography>
                          }
                        />
                      </HookRadioGroupField>
                    </Stack>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  rowSpacing={ele?.type === 'Single' ? 2 : 2.5}
                  sx={{
                    '& .MuiFormGroup-root': {
                      width: '100%',
                    },
                    'pt': ele?.type === 'Single' ? 2.5 : 0.5,
                    'pb': ele?.type === 'Single' ? 0 : 2.5,
                  }}
                >
                  {ele?.type === 'Single' ? (
                    <HookRadioGroupField
                      control={control}
                      defaultValue={selectedValue}
                      row={true}
                      name={`questions[${index}].selectedAnswer`}
                      onChange={(e: any) => {
                        ele.choices.forEach((item: any, choiceIndex: any) => {
                          setValue(
                            `questions[${index}].choices[${choiceIndex}].isRight`,
                            choiceIndex + 1 === Number(e.target.defaultValue)
                          );
                        });
                      }}
                      sx={{
                        'width': '100%',
                        '& .MuiFormGroup-root': {
                          width: '100%',
                        },
                      }}
                    >
                      {ele?.choices?.map(
                        (choiceItem: any, choiceIndex: any) => {
                          return (
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              // lg={3}
                              // xl={3}
                              key={choiceIndex.toString()}
                              pb={2.5}
                            >
                              <FormControlLabel
                                sx={{
                                  'width': '100%',
                                  '& .MuiTypography-root': {
                                    display: 'flex',
                                    width: '100%',
                                  },
                                }}
                                value={`${choiceIndex + 1}`}
                                control={
                                  <Radio
                                    disabled={!!(id && ele?._id)}
                                    sx={{
                                      '&, &.Mui-checked': {
                                        color:
                                          id && ele?._id
                                            ? 'rgba(0, 0, 0, 0.26)'
                                            : 'auto',
                                      },
                                    }}
                                  />
                                }
                                label={
                                  <HookTextArea
                                    rows={2}
                                    disabled={!!(id && ele?._id)}
                                    sx={{
                                      display: 'flex',
                                      flexGrow: 1,
                                      flexDirection: 'column',
                                      width: '100%',
                                    }}
                                    fullWidth
                                    control={control}
                                    name={`questions[${index}].choices[${choiceIndex}].choiceText`}
                                    label="Answer"
                                    customError={true}
                                    error={
                                      !!errors?.questions?.[index]?.choices?.[
                                      choiceIndex
                                      ]
                                    }
                                  />
                                }
                              />
                            </Grid>
                          );
                        }
                      )}
                    </HookRadioGroupField>
                  ) : (
                    <HookCheckboxField
                      control={control}
                      defaultValue="selectedAnswer"
                      name={`questions[${index}].selectedAnswer`}
                    >
                      {ele?.choices?.map(
                        (choiceItem: any, choiceIndex: any) => {
                          return (
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              // lg={3}
                              // xl={3}
                              key={choiceIndex.toString()}
                            >
                              <FormControlLabel
                                sx={{
                                  'width': '100%',
                                  '& .MuiTypography-root': {
                                    display: 'flex',
                                    width: '100%',
                                  },
                                }}
                                value={`${choiceIndex + 1}`}
                                control={
                                  <Checkbox
                                    disabled={!!(id && ele?._id)}
                                    defaultChecked={choiceItem?.isRight}
                                    onChange={(e) => {
                                      setValue(
                                        `questions[${index}].choices[${choiceIndex}].isRight`,
                                        e.target.checked
                                      );
                                    }}
                                    sx={{
                                      '&, &.Mui-checked': {
                                        color:
                                          id && ele?._id
                                            ? 'rgba(0, 0, 0, 0.26)'
                                            : '#3C4764',
                                      },
                                    }}
                                  />
                                }
                                label={
                                  <HookTextArea
                                    rows={2}
                                    disabled={!!(id && ele?._id)}
                                    sx={{
                                      display: 'flex',
                                      flexGrow: 1,
                                      width: '100%',
                                    }}
                                    fullWidth
                                    control={control}
                                    name={`questions[${index}].choices[${choiceIndex}].choiceText`}
                                    label="Answer"
                                    customError={true}
                                    error={
                                      !!errors?.questions?.[index]?.choices?.[
                                      choiceIndex
                                      ]
                                    }
                                  />
                                }
                              />
                            </Grid>
                          );
                        }
                      )}
                    </HookCheckboxField>
                  )}
                </Grid>
                <Grid container item xs={12} mr={'10px'} mb={2}>
                  <HookTextArea
                    disabled={!!(id && ele?._id)}
                    // inputProps={{ maxLength: 1000 }}
                    name={`questions[${index}].hint`}
                    label={'Hint'}
                    control={control}
                    rows={2}
                    isRequired={false}
                  />
                </Grid> */}

                <Box display={'flex'} justifyContent={'end'} mb={1}>
                  {questions.length > 1 && (
                    <Button
                      variant="outlined"
                      sx={{ mr: 2, minWidth: '99px' }}
                      onClick={() => {
                        if (ele?._id) {
                          handleClickOpen(ele);
                        } else {
                          remove(index);
                        }
                      }}
                    >
                      {textConst.delete}
                    </Button>
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
            <Grid container justifyContent={'end'} mt={2}>
              {questions.length - index === 1 && (
                <Button
                  onClick={handleAddingItems}
                  variant="contained"
                  sx={{ py: 0.9 }}
                >
                  {textConst.add +
                    ' ' +
                    textConst.more +
                    ' ' +
                    textConst.questions}
                </Button>
              )}
            </Grid>
          </React.Fragment>
        );
      })}

      {openDeleteDialog && (
        <AlertDialog
          open={openDeleteDialog}
          handleClose={handleClose}
          isLoading={isDeleteLoading}
          handleSave={() => handleDelete(selectedRecord)}
          title={textConst.areYouSure}
          content={textConst.areYouSureContent}
          saveBtnText={textConst.delete}
        />
      )}
    </>
  );
};

export default LearningQuestions;
