import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import config from '../../config';
import {
  getCookie,
  setCookie,
  deleteAllCookies,
  jwtDecode,
} from '../../utils/commonFunctions';
import { setUserInfo } from '../user';

const refreshQuery = fetchBaseQuery({
  baseUrl: config.baseUrl,
  prepareHeaders: (headers) => {
    const token = getCookie('refresh')
      ? JSON.parse(getCookie('refresh'))
      : false;
    if (token) {
      headers.set('x-refresh-token', token);
    }
    return headers;
  },
});

const baseQuery = fetchBaseQuery({
  baseUrl: config.baseUrl,
  prepareHeaders: (headers) => {
    const token = getCookie('token') ? JSON.parse(getCookie('token')) : false;
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    headers.set('Cache-Control', 'no-cache');
    headers.set('Pragma', 'no-cache');
    headers.set('If-Modified-Since', '0');
    return headers;
  },
});

const baseQueryWithReauth = async (
  args: any,
  api: any,
  extraOptions: any
): Promise<any> => {
  const result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    const refToken = getCookie('refresh')
      ? JSON.parse(getCookie('refresh'))
      : false;
    if (refToken) {
      const { data: refreshResult }: any = await refreshQuery(
        {
          url: '/auth/refresh-token',
          method: 'POST',
          body: {
            refreshToken: refToken,
          },
        },
        api,
        extraOptions
      );
      if (
        refreshResult?.status === 'success' &&
        refreshResult?.data &&
        refreshResult?.data?.accessToken
      ) {
        setCookie('token', refreshResult.data.accessToken);
        setCookie('refresh', refreshResult.data.refreshToken);
        api.dispatch(
          setUserInfo(jwtDecode(refreshResult.data.accessToken).payload)
        );
        return await baseQuery(args, api, extraOptions);
      }
    } else {
      deleteAllCookies();
      api.dispatch({ type: 'logout' });
      // window.location.href = '/log-in';
      window.location.assign(`${process.env.REACT_APP_SERVER_REDIRECT_URL}`);
    }
  }
  if (result.error && result.error.status === 403) {
    console.log('You do not have permission to access', result.error);
    deleteAllCookies();
    api.dispatch({ type: 'logout' });
    // window.location.href = '/log-in';
    window.location.assign(`${process.env.REACT_APP_SERVER_REDIRECT_URL}`);
  }
  return result;
};

export const createApiInstance = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: ['GetUser'],
  endpoints: () => ({}),
});

export default createApiInstance;
