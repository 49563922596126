import * as React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
// eslint-disable-next-line import/named
import { TransitionProps } from '@mui/material/transitions';

import LoadingButton from './LoadingButton';
import textConst from '../utils/textConst';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type AlertDialogProps = {
  open: boolean;
  title: string;
  content: string;
  saveBtnText?: string;
  customColor?: any;
  handleClose: React.MouseEventHandler<HTMLButtonElement> | undefined;
  handleSave: React.MouseEventHandler<HTMLButtonElement> | undefined;
  isLoading?: boolean;
  [key: string]: any;
};

export default function AlertDialog({
  open,
  title,
  content,
  saveBtnText,
  customColor,
  handleClose,
  handleSave,
  isLoading,
  ...rest
}: AlertDialogProps) {
  return (
    <Dialog
      {...rest}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ px: 3, py: 2 }}>
        <Button variant="outlined" color="secondary" onClick={handleClose}>
          {textConst.cancel}
        </Button>
        {saveBtnText && (
          <LoadingButton
            isLoading={!!isLoading}
            onClick={handleSave}
            variant="outlined"
            color={customColor ? 'secondary' : 'inherit'}
            sx={{
              minWidth: '85px',
              color: (theme: any) => customColor || theme.palette.primary.dark,
            }}
          >
            {saveBtnText}
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
}
