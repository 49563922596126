import { Navigate, useRoutes } from 'react-router-dom';

import Settings from './courseSetting/Settings';
import AddDashboardSettings from './dashboardSetting/AddDashboardSettings';
import DashboardSettings from './dashboardSetting/DashboardSettings';

const SettingRouting = () => {
  const defaultNavigate = <Navigate to={'/settings'} />;

  const routes = [
    {
      path: '/',
      children: [
        {
          index: true,
          element: defaultNavigate,
        },
        {
          path: '/course-setting',
          element: <Settings />,
        },
        {
          path: '/dashboard-setting',
          element: <DashboardSettings />,
        },
        {
          path: '/dashboard-setting/new',
          element: <AddDashboardSettings />,
        },
      ],
    },
  ];
  return useRoutes(routes);
};

export default SettingRouting;
